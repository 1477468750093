import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "./../service/AppContext";
import ListTruckTasksNav from "./../components/ListTruckTasksNav";
//import Button from '@material-ui/core/Button';
//import AddIcon from '@material-ui/icons/Add';
//import {getAllTruckList} from './../service/FirebaseConfig';
import PrivateRoute from "./PrivateRoute";
import CreateLoad from "./CreateLoad";
import UpdateLoad from "./../mainpages/UpdateLoad";
import { ManageTrucks } from "./../mainpages/ManageTrucks";
import Login from "./../pages/Login";
//import TruckRegistration from "./../components/TruckRegistration";

import { Switch, Route } from "react-router-dom";
//import TabPuzzle from "./../mainpages/AcordionTes";
import { LoadTable } from "./../components/Tables/LoadTable";
//  import { TruckTable } from "./../components/Tables/TruckTable";
import SignupforTrial from "./../mainpages/SignupforTrial";

export default class Home extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      showandhideformcard: false
    };
  }
  toggleFormCard() {
    this.setState({
      showandhideformcard: !this.state.showandhideformcard
    });
  }
  render() {
    console.log("Home state", this.context.state);
    return (
      <Container fluid>
        <Row className="vh-100 ">
          <Col
            xs={{
              order: 12
            }}
            sm={this.context.state.currentUser ? 2 : 0}
            /* xs={{cols:auto,order:2}}  md={{cols:auto,order:0}} */
            className={this.context.state.currentUser ? "border" : "d-none"}
          >
            <ListTruckTasksNav />
          </Col>
          <Col
            /*    md={true} */
            sm={{
              order: 12,
              span: this.context.state.currentUser ? 10 : 12
            }}

            /* xs={12} md={9} */
          >
            <div>
              <Switch>
                <PrivateRoute path="/createload" component={CreateLoad} />
                <PrivateRoute path="/updateload" component={UpdateLoad} />

                {/* <PrivateRoute
                    path="/signupfortrial"
                    component={SignupforTrial}
                  /> */}
                <PrivateRoute path="/managetruck" component={ManageTrucks} />

                {/*  <PrivateRoute path="/login" component={Login} /> */}

                {/*  <Route
                  path="/login"
                  component={Login}
                 
                /> */}

                <PrivateRoute path="/" component={LoadTable} />
                {/* <Route
                    path="/createload"
                    render={props => <CreateLoad {...props} isAuthed={true} />}
                  />
                  <Route
                    path="/updateload"
                    render={props => <UpdateLoad {...props} isAuthed={true} />}
                  />
                  <Route
                    path="/signupfortrial"
                    render={props => (
                      <SignupforTrial {...props} isAuthed={false} />
                    )}
                  />
                  <Route
                    path="/login"
                    render={props => <Login {...props} isAuthed={false} />}
                  />
                  <Route path="/managetruck">
                    <ManageTrucks />  <TruckRegistration /> 
                    <TruckTable /> 
                  </Route>
                  <PrivateRoute path="/test" component={CreateLoad} />
                  <Route exact path="/">
                    <PrivateRoute />
                     <LoadTable /> 
                  </Route> */}
              </Switch>{" "}
            </div>{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>
    );
  }
}
/* 
                                    <AcordionTes /> 

                                                       <TabPuzzle />
                                                  {getAllTruckList}  {this.state.showandhideformcard &&< LoadFormCard/>} 
                                                 <Switch>
                                                   <Route
                                                     path="/createload"
                                                     render={props => (
                                                       <CreateLoad {...props}  isAuthed={true} />
                                                     )}
                                                   />
                                                   <Route
                                                     path="/updateload"
                                                     render={props => (
                                                       <UpdateLoad {...props} isAuthed={true}   />
                                                     )}
                                                   />
                                                   <Route path="/managetruck">
                                                     <ManageTrucks />
                                                      <TruckRegistration />
                                                             <TruckTable /> 
                                                   </Route>
                                                   <Route exact path="/">
                                                     <LoadTable />
                                                   </Route>
                                                 </Switch> */
