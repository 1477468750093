import { format, parseISO } from "date-fns";
import Typography from "@material-ui/core/Typography";
const USLocale = require("date-fns/locale/en-US");

export const LOAD_COLUMNS = [
  {
    accessor: "loadId",
    Header: "Id"
  },
  {
    accessor: "shippingMethod",
    Header: "Ship Method",
    flex: 0.9
  },

  {
    accessor: "selectedShipper",
    headerAlign: "center",
    Header: "Shipper",
    Cell: props => {
      if (props.value) {
        return [...props.value].map(value => (
          <Typography color="textSecondary">
            {value.shipperId}: {value.shipperName}
          </Typography>
        ));
      } else {
        return null;
      }
    },
    flex: 1
  },

  {
    accessor: "selectedTruck",
    Header: "Truck",
    Cell: props => {
      if (props.value) {
        return [...props.value].map(value => (
          <Typography color="textSecondary">
            {value.truckId}: {value.make}: {value.model}: {value.vin}
          </Typography>
        ));
      } else {
        return null;
      }
    },
    flex: 1
  },

  {
    Header: "Origin",
    accessor: "origin",
    Cell: ({ value }) => {
      return (
        <div>
          <Typography> {value.loadLocation} </Typography>
          {value.loadTime && (
            <Typography color="textSecondary">
              {format(parseISO(value.loadTime), "PPpp", {
                locale: USLocale
              })}
            </Typography>
          )}
        </div>
      );
    }
  },
  {
    accessor: "destination",
    Header: "Pick Up",
    Cell: ({ value }) => {
      return (
        <div>
          <Typography> {value.pickupLocation} </Typography>
          {value.pickupTime && (
            <Typography color="textSecondary">
              {format(parseISO(value.pickupTime), "PPpp", {
                locale: USLocale
              })}
            </Typography>
          )}
        </div>
      );
    }
  },
  {
    accessor: "selectedDrivers",
    Header: "Driver(s)",
    Cell: props => {
      if (props.value) {
        return [...props.value].map(value => (
          <Typography color="textSecondary">
            {value.driverId}: {value.driverName}
          </Typography>
        ));
      } else {
        return null;
      }
    },
    flex: 1
  },
  {
    accessor: "selectedConsignee",
    Header: "Consignee",
    Cell: props => {
      if (props.value) {
        return [...props.value].map(value => (
          <Typography color="textSecondary">
            {value.consigneeId}: {value.consigneeName}
          </Typography>
        ));
      } else {
        return null;
      }
    },
    flex: 1
  },

  {
    accessor: "quantity",
    Header: "Quantity",
    flex: 0.5
  },
  {
    accessor: "dimension",
    Header: "Dimension",
    Cell: props => {
      const { l, w, h, dUnit } = props.value;
      return (
        <p>
          {l}x {w}x {h} {dUnit}
        </p>
      );
    },
    flex: 0.5
  },
  {
    accessor: "weight",
    Header: "Weight",
    Cell: props => {
      const { weightValue, weightUnit } = props.value;
      return (
        <span>
          {weightValue} {weightUnit}
        </span>
      );
    },
    flex: 0.5
  },
  {
    accessor: "trip_detail",
    Header: "Trip Detail",
    Cell: props => {
      const { t_cost, t_distace } = props.value;
      return (
        <span>
          $ {t_cost}, {t_distace}
          mi
        </span>
      );
    },
    flex: 1
  }
];
export const Truck_COLUMNS = [
  {
    accessor: "truckId",
    Header: "Id"
  },
  {
    accessor: "make",
    Header: "Make",
    flex: 0.9
  },

  {
    accessor: "model",
    headerAlign: "center",
    Header: "Model",
    Cell: props => {
      if (props.value) {
        return [...props.value].map(value => (
          <Typography color="textSecondary">
            {value.shipperId}:{value.shipperName}
          </Typography>
        ));
      } else {
        return null;
      }
    },
    flex: 1
  },

  {
    Header: "Year",
    accessor: "year"
    /*  Cell: ({ value }) => {
      return (
        <div>
          <Typography>{value.loadLocation}</Typography>
          {value.loadTime && (
            <Typography color="textSecondary">
              {format(parseISO(value.loadTime), "PPpp", {
                locale: USLocale
              })}
            </Typography>
          )}
        </div>
      );
      
    } */
  },
  {
    accessor: "vin",
    Header: "VIN"
    /* Cell: ({ value }) => {
      return (
        <div>
          <Typography>{value.pickupLocation}</Typography>
          {value.pickupTime && (
            <Typography color="textSecondary">
              {format(parseISO(value.pickupTime), "PPpp", {
                locale: USLocale
              })}
            </Typography>
          )}
        </div>
      );
    } */
  },
  {
    accessor: "color",
    Header: "Color",
    /*  Cell: props => {
      return [...props.value].map(value => (
        <Typography color="textSecondary">
          {value.consigneeId}:{value.consigneeName}
        </Typography>
      ));
    }, */
    flex: 1
  },

  {
    accessor: "fuelTankSize",
    Header: "Fuel Tank Size",
    flex: 0.5
  },

  {
    accessor: "notes",
    Header: "Notes",
    /*     Cell: props => {
          const { weightValue, weightUnit } = props.value;
          return (
            <span>
              
              {weightValue} {weightUnit}
            </span>
          );
        }, */
    flex: 0.5
  }
];
