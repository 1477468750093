import React, { createRef } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Button from "react-bootstrap/Button";
//import Row from "react-bootstrap/Row";
import "./../styles/CommonStyle.css";

import Col from "react-bootstrap/Col";
import { postLoad } from "../service/FirebaseLayer";
//import firebase from 'firebase/app';
import { AppContext } from "./../service/AppContext";
import { CrudOperationModal } from "./../components/CrudOperationModal";
import PlaceAutoComplete from "./../components/PlaceAutoComplete";
import { Typeahead } from "react-bootstrap-typeahead";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// Import as a module in your JS
import "react-bootstrap-typeahead/css/Typeahead.css";
import { withRouter } from "react-router-dom";

export class UpdateLoad extends React.Component {
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      loadId: 0,

      modelvalue: [
        {
          id: 0,
          title: "Truck Registration"
        },
        {
          id: 1,
          title: "Driver Registration"
        },
        {
          id: 2,
          title: "Shipper Registration"
        },
        {
          id: 3,
          title: "Consignee Registration"
        }
      ],
      selectedDrivers: [],
      checkedShippingMethod: "FLT",
      drivers: [],

      selectedTruck: [],
      selectedShipper: "",
      selectedConsignee: "",

      loadTime: new Date(),
      pickupTime: new Date(),

      trucklocal: "[]",

      distance: 0,

      fromLoadTable: null
    };
    this.loadId = createRef({});
    this.loadLocation = createRef({});
    this.pickupLocation = createRef({});
    this.weightValue = createRef();
    this.weightUnit = createRef();
    this.l = createRef();
    this.w = createRef();
    this.h = createRef();
    this.dUnit = createRef();
    this.quantity = createRef();
    this.t_rate = createRef();
    this.t_distance = createRef();
    this.t_cost = createRef();
  }

  componentDidMount() {
    const { fromLoadTable } = this.props.location.state;
    this.setState({
      fromLoadTable: fromLoadTable
    });

    this.setState({
      loadId: fromLoadTable.loadId,
      checkedShippingMethod: fromLoadTable.shippingMethod,
      selectedTruck: fromLoadTable.selectedTruck,
      selectedDrivers: fromLoadTable.selectedDrivers,
      selectedShipper: fromLoadTable.selectedShipper,
      selectedConsignee: fromLoadTable.selectedConsignee,
      loadTime: new Date(fromLoadTable.origin.loadTime),
      pickupTime: new Date(fromLoadTable.destination.pickupTime)
    });
    this.loadLocation.current.state.address = fromLoadTable.origin.loadLocation;
    this.pickupLocation.current.state.address =
      fromLoadTable.destination.pickupLocation;
    this.weightValue.current.value = fromLoadTable.weight.weightValue;
    this.weightUnit.current.value = fromLoadTable.weight.weightUnit;
    this.l.current.value = fromLoadTable.dimension.l;
    this.w.current.value = fromLoadTable.dimension.w;
    this.h.current.value = fromLoadTable.dimension.h;
    this.dUnit.current.value = fromLoadTable.dimension.dUnit;
    this.quantity.current.value = fromLoadTable.quantity;
    this.t_rate.current.value = fromLoadTable.trip_detail.t_rate;
    this.t_distance.current.value = fromLoadTable.trip_detail.t_distace;
    this.t_cost.current.value = fromLoadTable.trip_detail.t_cost;

    //// this.state.selectedTruck.push(fromLoadTable.selectedTruck);
    console.log(
      "from notif",

      fromLoadTable.origin.loadTime
    );
    /*    if (this.context.state.loads.length > 0) {
        this.setState({
          loadId: parseInt(
            this.context.state.loads[this.context.state.loads.length - 1].key
          )
        });
        console.log(
          "loads in load form",
          this.context.state.loads[this.context.state.loads.length - 1].key
        );
      } */
    //   console.log("this context",   this.context.state.loadId,this.context.state.loads)
    /*  let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
                this.setState({trucklocal:truckFromLStore})
               setTimeout(()=>{
                 console.log("From Truck  local store LOAD FORM" ,this.state.trucklocal);
               },1000) */
    console.log("current time", this.state.loadTime);
    console.log(
      "Drivers",
      this.context.state.drivers.map(driver => ({
        label: driver.driverName,
        value: driver.driverId
      }))
    );
    //  this.setState({trackDrivers:this.context.state.drivers.map(driver=>({label:driver.driverName,value:driver.driverId}))}) ;
  }

  componentWillUnmount() {}
  onChangeShippingMethod(event) {
    console.log(event.target.value);
  }
  onChangeSelectedDriver(event) {
    console.log("event", JSON.stringify(event));
    // let temp= (JSON.stringify(event)).map(result=>({lable:"hi", value: "low"}));
    this.setState({
      selectedDrivers: event
    });
    // console.log("selected driver",this.state.selectedDriver);
  }

  onChangeDistance() {
    this.t_cost.current.value =
      this.t_rate.current.value * this.t_distance.current.value;
  }
  onChangeRates() {
    this.t_cost.current.value =
      this.t_rate.current.value * this.t_distance.current.value;
  }

  handleSubmit = event => {
    debugger;
    event.preventDefault();
    let newLoad = {
      loadId: this.loadId.current.value,
      shippingMethod: this.state.checkedShippingMethod,
      selectedTruck: this.state.selectedTruck.map(value => {
        return {
          truckId: value.truckId,
          make: value.make,
          model: value.model,
          vin: value.vin
        };
      }),

      selectedDrivers: this.state.selectedDrivers.map(value => {
        return {
          driverId: value.driverId,
          driverName: value.driverName
        };
      }),
      selectedShipper: this.state.selectedShipper.map(value => {
        return {
          shipperId: value.shipperId,
          shipperName: value.shipperName
        };
      }),
      selectedConsignee: this.state.selectedConsignee.map(value => {
        return {
          consigneeId: value.consigneeId,
          consigneeName: value.consigneeName
        };
      }),
      origin: {
        loadLocation: this.loadLocation.current.state.address,
        loadTime: this.state.loadTime
      },
      destination: {
        pickupLocation: this.pickupLocation.current.state.address,
        pickupTime: this.state.pickupTime
      },
      weight: {
        weightValue: this.weightValue.current.value,
        weightUnit: this.weightUnit.current.value
      },
      dimension: {
        l: this.l.current.value,
        w: this.w.current.value,
        h: this.h.current.value,
        dUnit: this.dUnit.current.value
      },
      quantity: this.quantity.current.value,
      trip_detail: {
        t_rate: this.t_rate.current.value,
        t_distace: this.t_distance.current.value,
        t_cost: this.t_cost.current.value
      }
    };
    const { history } = this.props;

    postLoad(newLoad)
      .then(result => {
        console.log("UPDATED", result, this.context.state.loads);
        if (history) history.push("/");
        // this.props.history.push('/');
      })
      .catch(error => {
        console.log("Error", error);
      });
    //  this.props.handleClose();
  };
  onValueShippingMethodChange(event) {
    this.setState({
      checkedShippingMethod: event.target.value
    });
  }

  render() {
    console.log("Modal value", this.state.modelvalue);

    return (
      <Card>
        <CardContent>
          <Form onSubmit={this.handleSubmit}>
            <Form.Row className="border">
              <Form.Group xs={3} sm={1} as={Col}>
                <Form.Label column="sm"> ID </Form.Label>{" "}
                <Form.Control
                  className="text-muted"
                  size="sm"
                  ref={this.loadId}
                  value={this.state.loadId}
                />{" "}
                {/* <Form.Control className="text-muted" size="sm" ref="loadId" value={"L"+(this.state.loadId+1)} /> */}{" "}
              </Form.Group>{" "}
              <Form.Group xs={5} sm={2} as={Col} controlId="formBasicEmail">
                <Form.Label column="sm"> Ship Method </Form.Label>{" "}
                <InputGroup className="justify-content-around">
                  <Form.Check
                    type="radio"
                    id="1"
                    name="shippingMethod"
                    label="FLT"
                    value="FLT"
                    checked={this.state.checkedShippingMethod === "FLT"}
                    onChange={this.onValueShippingMethodChange.bind(this)}
                  />{" "}
                  <Form.Check
                    type="radio"
                    name="shippingMethod"
                    id="2"
                    label="LTL"
                    value="LTL"
                    checked={this.state.checkedShippingMethod === "LTL"}
                    onChange={this.onValueShippingMethodChange.bind(this)}
                  />{" "}
                </InputGroup>{" "}
              </Form.Group>{" "}
              <Form.Group xs={5} sm={4} as={Col}>
                <Form.Label column="sm"> Truck </Form.Label>{" "}
                <InputGroup>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey={option => `${option.truckId}:${option.model}`}
                    defaultSelected={this.state.selectedTruck}
                    onChange={
                      selectedTruck => {
                        //  this.state.selectedTruck.push(value);
                        this.setState({
                          selectedTruck
                        });
                        // this.state.selectedTruck.push(value);
                      } /* setSingleSelections */
                    }
                    options={this.context.state.trucks}
                    placeholder="Choose a state..."
                    selected={this.state.selectedTruck}
                    renderMenuItemChildren={option => (
                      <div>
                        {" "}
                        {option.truckId}{" "}
                        <div>
                          <small> MODEL: {option.model} </small> <br />
                          <small> VIN: {option.vin} </small>{" "}
                        </div>{" "}
                      </div>
                    )}
                  />{" "}
                  <InputGroup.Append>
                    <CrudOperationModal {...this.state.modelvalue[0]} />{" "}
                  </InputGroup.Append>{" "}
                </InputGroup>{" "}
                {/*  <Form.Text className="text-muted">
                                                              {Object.values(this.state.selectedTruck).join(",")}
                                                            </Form.Text> */}{" "}
              </Form.Group>{" "}
              <Form.Group xs={5} sm={5} as={Col}>
                <Form.Label column="sm"> Driver </Form.Label>{" "}
                <InputGroup>
                  <div>
                    <Typeahead
                      id="public-methods-example"
                      labelKey={option =>
                        `${option.driverId} ${option.driverName}`
                      }
                      /*  labelKey="driverId" */
                      multiple
                      highlightOnlyResult={true}
                      // defaultSelected={this.context.state.drivers.slice(0, 4)}
                      defaultSelected={this.state.selectedDrivers}
                      /*   inputProps={{
  style: { fontSize: "4004px", width: 30 },
  align: "right"
}} */
                      onChange={
                        selectedDrivers => {
                          //  this.state.selectedTruck.push(value);
                          this.setState({
                            selectedDrivers
                          });
                          // this.state.selectedTruck.push(value);
                        } /* setSingleSelections */
                      }
                      /*  renderToken={(option, props, index) => {
                console.log("render Token", option, props, index);
               
              }} */
                      options={this.context.state.drivers}
                      placeholder="Choose a state..."
                      selected={this.state.selectedDrivers}
                      renderMenuItemChildren={option => (
                        <div>
                          {" "}
                          {option.driverId}{" "}
                          <div>
                            <small> Name: {option.driverName} </small> <br />
                            <small> Email: {option.driverEmail} </small>{" "}
                          </div>{" "}
                        </div>
                      )}
                    />{" "}
                  </div>{" "}
                  <InputGroup.Append>
                    <CrudOperationModal {...this.state.modelvalue[1]} />{" "}
                  </InputGroup.Append>{" "}
                </InputGroup>{" "}
                {/*  <Form.Text className="text-muted">
                                                          
                                                          {Object.entries(this.state.selectedDrivers).map(
                                                            ([key, value], i) => {
                                                              console.log(
                                                                "result",
                                                                key,
                                                                value,
                                                                i,
                                                                Object.values(value)
                                                              );

                                                              return <p> {Object.values(value).join(",")} </p>;
                                                            }
                                                          )}
                                                          </Form.Text> */}{" "}
                {/*  {this.state.selectedDrivers.length>0?this.state.selectedDrivers[0].join(','):''} */}{" "}
              </Form.Group>{" "}
              {/*  */}{" "}
            </Form.Row>{" "}
            <Form.Row>
              <Form.Group xs={5} sm={6} as={Col}>
                <fieldset className="scheduler-border">
                  <legend className="scheduler-border"> Load: </legend>{" "}
                  <Form.Label> Shipper / Sender </Form.Label>{" "}
                  <InputGroup>
                    <Typeahead
                      id="basic-typeahead-single2"
                      labelKey={option =>
                        `${option.shipperId}:${option.shipperName}`
                      }
                      defaultSelected={this.state.selectedShipper}
                      onChange={
                        selectedShipper => {
                          //  this.state.selectedTruck.push(value);
                          this.setState({
                            selectedShipper
                          });
                          // this.state.selectedTruck.push(value);
                        } /* setSingleSelections */
                      }
                      options={this.context.state.shippers}
                      placeholder="Choose a Shipper..."
                      selected={this.state.selectedShipper}
                      renderMenuItemChildren={option => (
                        <div>
                          {" "}
                          {option.shipperId}{" "}
                          <div>
                            <small> Name: {option.shipperName} </small>{" "}
                          </div>{" "}
                        </div>
                      )}
                    />{" "}
                    <InputGroup.Append>
                      <CrudOperationModal {...this.state.modelvalue[2]} />{" "}
                    </InputGroup.Append>{" "}
                  </InputGroup>{" "}
                  <Form.Label> Location </Form.Label>{" "}
                  <PlaceAutoComplete ref={this.loadLocation} />{" "}
                  <Form.Label> Time </Form.Label>{" "}
                  <InputGroup>
                    {" "}
                    {/*  <Form.Control size="sm"    type="datetime-local"  value={this.state.loadTime}  /> */}{" "}
                    <DatePicker
                      selected={this.state.loadTime}
                      showTimeSelect
                      dateFormat="Pp"
                      onChange={date =>
                        this.setState({
                          loadTime: date
                        })
                      }
                    />{" "}
                  </InputGroup>{" "}
                  <Form.Label> Bill of Loading(BOL) </Form.Label>{" "}
                  <InputGroup>
                    <Form.Control type="file" />
                  </InputGroup>{" "}
                </fieldset>{" "}
              </Form.Group>{" "}
              <Form.Group xs={5} sm={6} as={Col}>
                <fieldset className="scheduler-border">
                  <legend className="scheduler-border"> Pick Up: </legend>{" "}
                  <Form.Label> Consignee / Receiver </Form.Label>{" "}
                  <InputGroup>
                    <Typeahead
                      id="basic-typeahead-single1"
                      labelKey={option =>
                        `${option.consigneeId}:${option.consigneeName}`
                      }
                      defaultSelected={this.state.selectedConsignee}
                      onChange={
                        selectedConsignee => {
                          //  this.state.selectedTruck.push(value);
                          this.setState({
                            selectedConsignee
                          });
                          // this.state.selectedTruck.push(value);
                        } /* setSingleSelections */
                      }
                      options={this.context.state.consignees}
                      placeholder="Choose a consignee..."
                      selected={this.state.selectedConsignee}
                      renderMenuItemChildren={option => (
                        <div>
                          {" "}
                          {option.consigneeId}{" "}
                          <div>
                            <small> Name: {option.consigneeName} </small> <br />
                          </div>{" "}
                        </div>
                      )}
                    />{" "}
                    <InputGroup.Append>
                      <CrudOperationModal {...this.state.modelvalue[3]} />{" "}
                    </InputGroup.Append>{" "}
                  </InputGroup>{" "}
                  <Form.Label> Location </Form.Label>{" "}
                  <PlaceAutoComplete ref={this.pickupLocation} />{" "}
                  <Form.Label> Time </Form.Label>{" "}
                  <InputGroup>
                    {" "}
                    {/*  <Form.Control size="sm"    type="datetime-local"  value={this.state.loadTime}  /> */}{" "}
                    <DatePicker
                      selected={this.state.pickupTime}
                      showTimeSelect
                      dateFormat="Pp"
                      onChange={date =>
                        this.setState({
                          pickupTime: date
                        })
                      }
                    />{" "}
                  </InputGroup>{" "}
                  {/*  <Form.Text className="text-muted">
                                                                                                        We'll never share your email with anyone else.
                                                                                                        </Form.Text> */}{" "}
                </fieldset>{" "}
              </Form.Group>{" "}
            </Form.Row>{" "}
            <fieldset className="scheduler-border">
              <legend className="scheduler-border"> Load Detail: </legend>{" "}
              <Form.Row>
                <Form.Group xs={5} sm={3} as={Col}>
                  <Form.Label> Weight </Form.Label>{" "}
                  <InputGroup>
                    <Form.Control
                      size="sm"
                      ref={this.weightValue}
                      placeholder=""
                    />
                    <InputGroup.Append>
                      <Form.Group>
                        <Form.Control
                          size="sm"
                          ref={this.weightUnit}
                          as="select"
                        >
                          <option> lb. </option> <option> kg </option>
                        </Form.Control>{" "}
                      </Form.Group>{" "}
                    </InputGroup.Append>{" "}
                  </InputGroup>{" "}
                </Form.Group>{" "}
                <Form.Group xs={5} sm={3} as={Col}>
                  <Form.Label> Dimension </Form.Label>{" "}
                  <InputGroup className="justify-item-around">
                    <Form.Control size="sm" ref={this.l} placeholder="L" />
                    <Form.Control size="sm" ref={this.w} placeholder="W" />
                    <Form.Control size="sm" ref={this.h} placeholder="H" />
                    <InputGroup.Append>
                      <Form.Group>
                        <Form.Control size="sm" ref={this.dUnit} as="select">
                          <option> in </option> <option> cm </option>
                        </Form.Control>{" "}
                      </Form.Group>{" "}
                    </InputGroup.Append>{" "}
                  </InputGroup>{" "}
                </Form.Group>{" "}
                <Form.Group xs={5} sm={3} as={Col}>
                  <Form.Label> Quantity </Form.Label>{" "}
                  <Form.Control size="sm" ref={this.quantity} placeholder="" />
                </Form.Group>{" "}
              </Form.Row>{" "}
            </fieldset>{" "}
            <fieldset className="scheduler-border">
              <legend className="scheduler-border"> Trip Detail: </legend>{" "}
              <Form.Row>
                <Form.Group xs={5} sm={2} as={Col}>
                  <Form.Label> Rates / mile($) </Form.Label>{" "}
                  <Form.Control
                    size="sm"
                    ref={this.t_rate}
                    onChange={() => this.onChangeRates()}
                    placeholder=""
                  />
                </Form.Group>{" "}
                <Form.Group xs={5} sm={2} as={Col}>
                  <Form.Label> Distance(miles) </Form.Label>{" "}
                  <Form.Control
                    size="sm"
                    ref={this.t_distance}
                    onChange={() => this.onChangeDistance()}
                    placeholder=""
                  />
                </Form.Group>{" "}
                <Form.Group xs={5} sm={2} as={Col}>
                  <Form.Label> Cost($) </Form.Label>{" "}
                  <Form.Control size="sm" ref={this.t_cost} placeholder="" />
                </Form.Group>{" "}
              </Form.Row>{" "}
            </fieldset>{" "}
            <Form.Row>
              <Form.Group
                sm={{
                  offset: 11
                }}
                as={Col}
              >
                {/*    */}{" "}
                <Button
                  style={{
                    backgroundColor: "#0077be"
                  }}
                  type="submit"
                >
                  Save{" "}
                </Button>{" "}
                {/*   */}{" "}
              </Form.Group>{" "}
            </Form.Row>{" "}
          </Form>{" "}
        </CardContent>{" "}
      </Card>
    );
  }
}
export default withRouter(UpdateLoad);
