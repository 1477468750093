import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TruckRegistration from "./TruckRegistration";
import ShipperRegistration from "./ShipperRegistration";
import DriverRegistration from "./DriverRegistration";
import ConsineeForm from "./ConsigneeForm";
import AddIcon from "@material-ui/icons/Add";
const CrudOperationModal = props => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log("Crud operation modal", props, show);
  return (
    <>
      <Button size="sm" variant="light" onClick={handleShow}>
        <AddIcon />
        New
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title size="sm"> {props.title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.id === 0 && <TruckRegistration handleClose={handleClose} />}
          {props.id === 1 && <DriverRegistration handleClose={handleClose} />}
          {props.id === 2 && <ShipperRegistration handleClose={handleClose} />}
          {props.id === 3 && <ConsineeForm handleClose={handleClose} />}
        </Modal.Body>
      </Modal>
    </>
  );
};
export { CrudOperationModal };
