import * as React from "react"

//import AppBar from '@material-ui/core/App'
//import Button from '@material-ui/core/Button';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'
import {
  Link,
  NavLink 
 
} from "react-router-dom";
const Statistics = () => {
  return (
    <div>
   

        <Container fluid >
        <Row>
            <Col>    Statistics</Col>
        </Row>
         <Row >
            
            <Col xs={12} sm={{order:12}}/* xs={12} md={9} */ >
         
    
           trucks <Link  to="/createload"  >  5</Link>
           
</Col>
</Row>
</Container>
     
</div>
  )
}
export default Statistics