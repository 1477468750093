import React, { Component, setState } from "react";

import Stepper from "react-stepper-horizontal";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { myColor } from "../styles/CommonStyle";
import SignUp from "./../pages/SignUp";
import BusinessProfile from "./../pages/BussinessProfile";
export default class SignupforTrial extends Component {
  state = {
    currentStep: 0
  };
  constructor() {
    super();
  }
  onClickNext = () => {
    console.log("current step", this.state.currentStep);
    if (this.state.currentStep < 4) {
      this.setState({
        currentStep: this.state.currentStep + 1
      });
    }
  };
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Stepper
              steps={[
                {
                  title: "Personal Information"
                },
                {
                  title: "Step Two"
                },
                {
                  title: "Step Three"
                },
                {
                  title: "Step Four"
                }
              ]}
              activeStep={this.state.currentStep}
              activeColor={myColor.yelloWColor}
              completeColor={myColor.blueColor}
            />{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col
            sm={{
              order: 1,
              span: 1
            }}
          ></Col>{" "}
          <Col
            sm={{
              order: 2,
              span: 10
            }}
            style={{
              padding: 30
            }}
          >
            {this.state.currentStep == 0 && (
              <SignUp onClickNext={this.onClickNext} />
            )}{" "}
            {this.state.currentStep == 1 && (
              <BusinessProfile onClickNext={this.onClickNext} />
            )}{" "}
          </Col>{" "}
          <Col
            sm={{
              order: 12,
              span: 1
            }}
          ></Col>{" "}
        </Row>{" "}
        <Row>
          <Col
            sm={{
              order: 11,
              span: 5
            }}
          ></Col>{" "}
          <Col
            sm={{
              order: 12,
              span: 7
            }}
          >
            {/*  <Button onClick={this.onClickNext}>Continue</Button> */}{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>
    );
  }
}
