//import logo from './logo.svg';
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HeaderHome from "./components/HeaderHome";
import HeaderLogin from "./components/HeaderLogin";
import Context from "./service/AppContext";
import Home from "./mainpages/Home";
import Login from "./pages/Login";
import SignupforTrial from "./mainpages/SignupforTrial";
//import Container from "react-bootstrap/Container";
function App() {
  return (
    <Context>
      <Router>
        <Switch>
          <Route path="/login">
            <HeaderLogin />
            <Login />
          </Route>
          <Route path="/signupfortrial" component={SignupforTrial} />
          <Route path="/">
            <HeaderHome />
            <Home />
          </Route>
        </Switch>
      </Router>
      {/* 
        <Header />
        <Home />
      </Router> */}
    </Context>
  );
}

export default App;
