import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";
import firebaseApp from "./../firebase";
export default class BusinessProfile extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    };
  }
  handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({
      [name]: value
    });
    console.log("Handle change", value, name, this.state);
  };
  signUp = e => {
    e.preventDefault();
    console.log("signup", this.state);
  };
  render() {
    return (
      <Form onSubmit={this.signUp}>
        <Form.Row>
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Business Name </Form.Label>{" "}
            <Form.Control
              size="sm"
              onChange={this.handleChange}
              name="firstName"
              value={this.state.firstName}
            />{" "}
          </Form.Group>{" "}
          <Form.Group>
            {" "}
            {/*  <Autocomplete
                                                                                        
                                                                                                                 options={this.context.state.trucks}
                                                                                                                 getOptionLabel={(option) =>  option.truckId + "," +option.make + "," + option.model}   
                                                                                                            
                                                                                                            
                                                                                                            onChange={(event, newValue) => {
                                                                                                                console.log("New value",newValue,newValue)
                                                                                                                this.setState({test:newValue?newValue.truckId:""})
                                                                                                            }} 
                                                                                                           
                                                                                                            style={{ width: 300}}
                                                                                                            renderInput={(params) => <TextField {...params} label="Custom filter" variant="outlined" />}
                                                                                                        
                                                                                      /> */}{" "}
          </Form.Group>{" "}
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Bussiness Type</Form.Label>{" "}
            <Form.Control
              size="sm"
              onChange={this.handleChange}
              name="lastName"
              value={this.state.lastName}
            />{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label>EIN </Form.Label>{" "}
            <Form.Control
              size="sm"
              type="email"
              onChange={this.handleChange}
              name="email"
              value={this.state.email}
            />{" "}
          </Form.Group>{" "}
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Phone Number </Form.Label>{" "}
            <Form.Control
              size="sm"
              type="phonenumber"
              onChange={this.handleChange}
              name="phoneNumber"
              value={this.state.phoneNumber}
            />{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Password </Form.Label>{" "}
            <Form.Control
              size="sm"
              onChange={this.handleChange}
              type="password"
              name="password"
              value={this.state.password}
            />{" "}
          </Form.Group>{" "}
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Confirm Password </Form.Label>{" "}
            <Form.Control
              size="sm"
              type="password"
              onChange={this.handleChange}
              name="confirmPassword"
              value={this.state.confirmPassword}
            />{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group className="d-flex justify-content-center" as={Col}>
            <Button
              style={{
                backgroundColor: "#0077be"
              }}
              type="submit"
            >
              Next{" "}
            </Button>{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
      </Form>
    );
  }
}
