/* import React, { Component } from "react";
import { Route } from "react-router-dom";
import { AppContext } from "./../service/AppContext";
export default class PrivateRoute extends Component {
  static contextType = AppContext;
  render() {
    console.log("Private route", this.context.state);
    return <Route render={props => props.component} />;
  }
} */

import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "./../service/AppContext";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { state } = useContext(AppContext);
  //  debugger;

  if (state.currentUser === undefined) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={routeProps =>
        state.currentUser && state.currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
