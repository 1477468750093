import * as React from "react";
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
import Form from "react-bootstrap/Form";
//import InputGroup from 'react-bootstrap/InputGroup';

import Button from "react-bootstrap/Button";
//import Row from "react-bootstrap/Row";
import "./../styles/CommonStyle.css";
//import AddIcon from '@material-ui/icons/Add';
//import Button from '@material-ui/core/Button';
import Col from "react-bootstrap/Col";
import {
  postDriver
} from "../service/FirebaseLayer";
//import firebase from 'firebase/app';
import {
  AppContext
} from "./../service/AppContext";
//import TextField from '@material-ui/core/TextField';
//import Autocomplete from '@material-ui/lab/Autocomplete';
//import { ComponetModel } from "./ComponentModel";
//import { createFilterOptions } from '@material-ui/lab';

export default class DriverRegistration extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      modelvalue: [{
        id: 0,
        title: "Truck Registration"
      }],
      driverId: 0,
      selectedTruckId: ""
    };
  }
  componentDidMount() {
    if (this.context.state.drivers.length > 0) {
      this.setState({
        driverId: parseInt(
          this.context.state.drivers[this.context.state.drivers.length - 1].key
        )
      });
    }
  }
  componentWillUnmount() {}

  handleSubmit = event => {
    event.preventDefault();
    let newdriver = {
      driverId: this.refs.driverId.value.substr(1),
      driverName: this.refs.driverName.value,
      driverPhone: this.refs.driverPhone.value,
      driverEmail: this.refs.driverEmail.value,

      notes: this.refs.notes.value
    };
    postDriver(newdriver)
      .then(() => {
        this.context.setState({
          drivers: [this.context.state.drivers, ...newdriver]
        });
        console.log("UPDATED", this.context.state.drivers);
      })
      .catch(() => {
        console.log("Error");
      });
    this.props.handleClose();
  };

  onChangeColor(event) {
    console.log(event.target.value);
  }

  render() {
    return ( <
      Form onSubmit = {
        this.handleSubmit
      } >
      <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        2
      }
      as = {
        Col
      } >
      <
      Form.Label > Driver Id < /Form.Label> <
      Form.Control className = "text-muted"
      ref = "driverId"
      size = "sm"
      value = {
        "D" + (this.state.driverId + 1)
      }
      readOnly /
      >
      <
      /Form.Group>

      <
      Form.Group xs = {
        5
      }
      sm = {
        10
      }
      as = {
        Col
      } >
      <
      Form.Label column = "sm" > NAME < /Form.Label> <
      Form.Control size = "sm"
      ref = "driverName" / >
      <
      /Form.Group> <
      /Form.Row> <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        5
      }
      as = {
        Col
      } >
      <
      Form.Label column = "sm" > PHONE NO. < /Form.Label> <
      Form.Control size = "sm"
      ref = "driverPhone" / >
      <
      /Form.Group> <
      Form.Group xs = {
        5
      }
      sm = {
        1
      }
      as = {
        Col
      } > < /Form.Group> <
      Form.Group xs = {
        6
      }
      sm = {
        6
      }
      as = {
        Col
      } >
      <
      Form.Label > EMAIL < /Form.Label> <
      Form.Control size = "sm"
      type = "email"
      ref = "driverEmail" / >
      <
      /Form.Group> <
      /Form.Row> <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        6
      }
      as = {
        Col
      } >
      <
      Form.Label > Notes < /Form.Label> <
      Form.Control as = "textarea"
      rows = {
        1
      }
      size = "sm"
      ref = "notes" / >
      <
      /Form.Group> <
      /Form.Row> <
      Form.Row >
      <
      Form.Group className = "offset-md-10"
      as = {
        Col
      }
      controlId = "formBasicCheckbox" >
      <
      Button style = {
        {
          backgroundColor: "#0077be"
        }
      }
      type = "submit" >
      Save <
      /Button> <
      /Form.Group> <
      /Form.Row> <
      /Form>
    );
  }
}