import React, { Component } from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../styles/CommonStyle.css";
import { AppContext } from "./../service/AppContext";
import firebaseApp from "./../firebase";

export default class AuthenticationDropDown extends Component {
  static contextType = AppContext;
  constructor() {
    super();
  }
  componentDidMount() {}
  render() {
    console.log("", this.context.state);

    return (
      <div>
        <Dropdown as={ButtonGroup} alignRight>
          <Dropdown.Toggle
            split
            variant="success"
            style={{
              borderRadius: 50
            }}
            id="dropdown-split-basic"
          >
            {this.context.state.currentUser &&
              this.context.state.currentUser.displayName
                .match(/\b(\w)/g)
                .join("")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <p>
                {this.context.state.currentUser &&
                  this.context.state.currentUser.displayName}
                <br />
                {this.context.state.currentUser &&
                  this.context.state.currentUser.email}
              </p>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1"></Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => firebaseApp.auth().signOut()}>
              Log out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
