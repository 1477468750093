import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";

import "./../styles/CommonStyle.css";

import Col from "react-bootstrap/Col";
import {
  postConsignee
} from "../service/FirebaseLayer";
//import firebase from 'firebase/app';
import {
  AppContext
} from "./../service/AppContext";

export default class ConsigneeRegistration extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      consigneeId: 0
    };
  }
  componentDidMount() {
    if (this.context.state.consignees.length > 0) {
      this.setState({
        consigneeId: parseInt(
          this.context.state.consignees[
            this.context.state.consignees.length - 1
          ].key
        )
      });
    }

    // let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
    // console.log("From Truck  local store TRUCK FORM" ,truckFromLStore);
  }
  componentWillUnmount() {}
  onChangeColor(event) {
    console.log(event.target.value);
  }
  handleSubmit = event => {
    event.preventDefault();
    let newConsignee = {
      consigneeId: this.refs.consigneeId.value,
      consigneeName: this.refs.consigneeName.value,
      consigneePhone: this.refs.consigneePhone.value,
      consigneeEmail: this.refs.consigneeEmail.value,
      consigneeAddress: this.refs.consigneeAddress.value,
      consigneeNotes: this.refs.consigneeNotes.value
    };
    postConsignee(newConsignee)
      .then(() => {
        this.context.setState({
          consignees: [this.context.state.consignees, ...newConsignee]
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
    this.props.handleClose();
  };
  render() {
    return ( <
      Form onSubmit = {
        this.handleSubmit
      } >
      <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        2
      }
      as = {
        Col
      } >
      <
      Form.Label > consigneeId < /Form.Label>{" "} <
      Form.Control className = "text-muted"
      ref = "consigneeId"
      size = "sm"
      value = {
        "C" + (this.state.consigneeId + 1)
      }
      readOnly /
      > {
        " "
      } <
      /Form.Group>{" "} <
      Form.Group xs = {
        5
      }
      sm = {
        6
      }
      as = {
        Col
      } >
      <
      Form.Label column = "sm" > NAME < /Form.Label>{" "} <
      Form.Control size = "sm"
      ref = "consigneeName" / >
      <
      /Form.Group>{" "} <
      Form.Group xs = {
        5
      }
      sm = {
        4
      }
      as = {
        Col
      } >
      <
      Form.Label column = "sm" > PHONE NUMBER < /Form.Label>{" "} <
      Form.Control size = "sm"
      ref = "consigneePhone" / >
      <
      /Form.Group>{" "} <
      /Form.Row>{" "} <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        3
      }
      as = {
        Col
      } >
      <
      Form.Label > EMAIL < /Form.Label>{" "} <
      Form.Control size = "sm"
      type = "email"
      ref = "consigneeEmail" / >
      <
      /Form.Group>{" "} <
      Form.Group xs = {
        5
      }
      sm = {
        9
      }
      as = {
        Col
      } >
      <
      Form.Label > ADDRESS < /Form.Label>{" "} <
      Form.Control size = "sm"
      ref = "consigneeAddress" / >
      <
      /Form.Group>{" "} {
        /* <Form.Group xs={5} sm={3} as={Col}>
                                        <Form.Label  >Color</Form.Label>   
                                        <Form.Control   size="sm" ref="color"/>
                                        
                                    </Form.Group>
                                    <Form.Group xs={5} sm={3} as={Col}>
                                        <Form.Label  >Fuel Tank Size</Form.Label>   
                                        <Form.Control   size="sm" ref="fuelTankSize"/>
                                        
                                    </Form.Group> */
      } {
        " "
      } <
      /Form.Row>{" "} <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        12
      }
      as = {
        Col
      } >
      <
      Form.Label > Notes < /Form.Label>{" "} <
      Form.Control as = "textarea"
      rows = {
        2
      }
      size = "sm"
      ref = "consigneeNotes" /
      >
      <
      /Form.Group>{" "} <
      /Form.Row>{" "} <
      Form.Row >
      <
      Form.Group className = "offset-md-10"
      as = {
        Col
      }
      controlId = "formBasicCheckbox" >
      <
      Button style = {
        {
          backgroundColor: "#0077be"
        }
      }
      type = "submit" >
      Save {
        " "
      } <
      /Button>{" "} <
      /Form.Group>{" "} <
      /Form.Row>{" "} <
      /Form>
    );
  }
}