import firebaseApp from "./../firebase"
/* import firebase from "firebase/app";
import "firebase/database";
var firebaseConfig = {
  apiKey: "AIzaSyCbRtvjw6LP7NW8UDrlbIAfqpx7xNpcGxM",
  authDomain: "mytrucx.firebaseapp.com",
  projectId: "mytrucx",
  storageBucket: "mytrucx.appspot.com",
  messagingSenderId: "246986986028",
  appId: "1:246986986028:web:d1a819a427fd371bfcb789",
  measurementId: "G-S3NWG3MRWN"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
(function () {
  console.log("This function is called immediately");
})(); */
let firebaseRef = firebaseApp.database();
const getAllTruckList = () => {
  firebaseRef.ref().on("value", snapshot => {
    const data = snapshot.val();
    console.log("DATA", data);
  });
};
const postLoad = load => {
  console.log("Load to post", load)
  return firebaseRef.ref("birhanu/Load/" + load.loadId.substr(1)).update(load);
};
const deleteLoad = loadId => {
  console.log("deleting records", loadId.substr(1))
  return firebaseRef.ref("birhanu/Load/" + loadId.substr(1)).remove();
};
const postTruck = truck => {
  
  return firebaseRef
    .ref("birhanu/Truck/" + truck.truckId.substr(1))
    .update(truck);

  // localStorage.removeItem('truckstore')
  //let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
  //console.log("From Truck  local store BEFORE" ,truckFromLStore);
  //truckFromLStore.push(truck);

  // console.log("From Truck  local store AFTER " ,truckFromLStore);

  //localStorage.setItem('truckstore',JSON.stringify(truckFromLStore));
};
/* let database = firebase.database();
database.ref().on('value',(snapshot) =>{
    const data = snapshot.val();
    console.log("DATA",data);
}) */
const postShipper = shipper => {
  console.log("shipper id", shipper.shipperId);
  return firebaseRef
    .ref("birhanu/Shippers/" + shipper.shipperId.substr(1))
    .update(shipper);
};
const postConsignee = consignee => {
  return firebaseRef
    .ref("birhanu/Consignees/" + consignee.consigneeId.substr(1))
    .update(consignee);
};
const postDriver = driver => {
  return firebaseRef.ref("birhanu/Drivers/" + driver.driverId).update(driver);
};
export {
  getAllTruckList,
  postLoad,
  deleteLoad,
  postTruck,
  postDriver,
  postShipper,
  postConsignee
};