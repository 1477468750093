import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./../styles/CommonStyle.css";
import AddIcon from "@material-ui/icons/Add";
//import Button from '@material-ui/core/Button';
import Col from "react-bootstrap/Col";
import {
  postShipper
} from "../service/FirebaseLayer";
//import firebase from 'firebase/app';
import {
  AppContext
} from "./../service/AppContext";

export default class ShipperRegistration extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      shipperId: 0
    };
  }
  componentDidMount() {
    if (this.context.state.shippers.length > 0) {
      this.setState({
        shipperId: parseInt(
          this.context.state.shippers[this.context.state.shippers.length - 1]
          .key
        )
      });
    }

    // let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
    // console.log("From Truck  local store TRUCK FORM" ,truckFromLStore);
  }
  componentWillUnmount() {}
  onChangeColor(event) {
    console.log(event.target.value);
  }
  handleSubmit = event => {
    event.preventDefault();
    let newShipper = {
      shipperId: this.refs.shipperId.value,
      shipperName: this.refs.shipperName.value,
      shipperPhone: this.refs.shipperPhone.value,
      shipperEmail: this.refs.shipperEmail.value,
      shipperAddress: this.refs.shipperAddress.value,
      shipperNotes: this.refs.shipperNotes.value
    };
    postShipper(newShipper)
      .then(() => {
        this.context.setState({
          shippers: [this.context.state.shippers, ...newShipper]
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
    this.props.handleClose();
  };
  render() {
    return ( <
      Form onSubmit = {
        this.handleSubmit
      } >
      <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        2
      }
      as = {
        Col
      } >
      <
      Form.Label > Ship.Id < /Form.Label>{" "} <
      Form.Control className = "text-muted"
      ref = "shipperId"
      size = "sm"
      value = {
        "S" + (this.state.shipperId + 1)
      }
      readOnly /
      >
      <
      /Form.Group>{" "} <
      Form.Group xs = {
        5
      }
      sm = {
        6
      }
      as = {
        Col
      } >
      <
      Form.Label column = "sm" > NAME < /Form.Label>{" "} <
      Form.Control size = "sm"
      ref = "shipperName" / >
      <
      /Form.Group>{" "} <
      Form.Group xs = {
        5
      }
      sm = {
        4
      }
      as = {
        Col
      } >
      <
      Form.Label column = "sm" > PHONE NUMBER < /Form.Label>{" "} <
      Form.Control size = "sm"
      ref = "shipperPhone" / >
      <
      /Form.Group> <
      /Form.Row>{" "} <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        3
      }
      as = {
        Col
      } >
      <
      Form.Label > EMAIL < /Form.Label>{" "} <
      Form.Control size = "sm"
      type = "email"
      ref = "shipperEmail" / >
      <
      /Form.Group>{" "} <
      Form.Group xs = {
        5
      }
      sm = {
        9
      }
      as = {
        Col
      } >
      <
      Form.Label > ADDRESS < /Form.Label>{" "} <
      Form.Control size = "sm"
      ref = "shipperAddress" / >
      <
      /Form.Group>{" "} {
        /* <Form.Group xs={5} sm={3} as={Col}>
                                                <Form.Label  >Color</Form.Label>   
                                                <Form.Control   size="sm" ref="color"/>
                                                
                                            </Form.Group>
                                            <Form.Group xs={5} sm={3} as={Col}>
                                                <Form.Label  >Fuel Tank Size</Form.Label>   
                                                <Form.Control   size="sm" ref="fuelTankSize"/>
                                                
                                            </Form.Group> */
      } <
      /Form.Row>{" "} <
      Form.Row >
      <
      Form.Group xs = {
        5
      }
      sm = {
        12
      }
      as = {
        Col
      } >
      <
      Form.Label > Notes < /Form.Label>{" "} <
      Form.Control as = "textarea"
      rows = {
        2
      }
      size = "sm"
      ref = "shipperNotes" / >
      <
      /Form.Group> <
      /Form.Row>{" "} <
      Form.Row >
      <
      Form.Group className = "offset-md-10"
      as = {
        Col
      }
      controlId = "formBasicCheckbox" >
      <
      Button style = {
        {
          backgroundColor: "#0077be"
        }
      }
      type = "submit" >
      Save {
        " "
      } <
      /Button>{" "} <
      /Form.Group> <
      /Form.Row>{" "} <
      /Form>
    );
  }
}