import React, { createRef } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PhoneIcon from "@material-ui/icons/Phone";
//import Button from "react-bootstrap/Button";

import "./../styles/CommonStyle.css";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { postLoad } from "../service/FirebaseLayer";

import { AppContext } from "./../service/AppContext";
import { CrudOperationModal } from "./../components/CrudOperationModal";
import PlaceAutoComplete from "./../components/PlaceAutoComplete";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";
// Import as a module in your JS
import "react-bootstrap-typeahead/css/Typeahead.css";
import { /*  Switch, Route, Link, */ withRouter } from "react-router-dom";
import "./../styles/TabBarStyle.module.css";
import { commonStyles, formGroupStyle } from "./../styles/CommonStyle";
import styles from "./../styles/commonstyle.module.css";
export class CreateLoad extends React.Component {
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      loadId: 0,

      modelvalue: [
        {
          id: 0,
          title: "Truck Registration"
        },
        {
          id: 1,
          title: "Driver Registration"
        },
        {
          id: 2,
          title: "Shipper Registration"
        },
        {
          id: 3,
          title: "Consignee Registration"
        }
      ],
      selectedDrivers: [],
      checkedShippingMethod: "FLT",
      //trackDrivers: [],
      selectedTruck: [],
      selectedShipper: [],
      selectedConsignee: [],
      loadTime: new Date(),
      loadLocation: "",
      pickupTime: new Date(),
      pickupLocation: "",
      weightValue: "",
      weightUnit: "lb",
      l: "",
      w: "",
      h: "",
      dUnit: "in",
      quantity: "",
      t_rate: "",
      t_distance: "",
      t_cost: "",

      tabIndexValue: 0,
      matches: window.matchMedia("(min-width: 768px)").matches
    };
    this.loadId = createRef({});
    //  this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    if (this.context.state.loads.length > 0) {
      this.setState({
        loadId: parseInt(
          this.context.state.loads[this.context.state.loads.length - 1].key
        )
      });
      console.log(
        "loads in load form",
        this.context.state.loads[this.context.state.loads.length - 1].key
      );
    }
    //   console.log("this context",   this.context.state.loadId,this.context.state.loads)
    /*  let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
                this.setState({trucklocal:truckFromLStore})
               setTimeout(()=>{
                 console.log("From Truck  local store LOAD FORM" ,this.state.trucklocal);
               },1000) */
    console.log(
      "consignees",
      this.context.state.consignees,
      this.context.state.trucks
    );
    console.log(
      "Drivers",
      this.context.state.drivers.map(driver => ({
        label: driver.driverName,
        value: driver.driverId
      }))
    );

    //  this.setState({trackDrivers:this.context.state.drivers.map(driver=>({label:driver.driverName,value:driver.driverId}))}) ;
  }

  componentWillUnmount() {}
  onChangeShippingMethod(event) {
    console.log(event.target.value);
  }
  onChangeSelectedDriver(event) {
    console.log("event", JSON.stringify(event));
    // let temp= (JSON.stringify(event)).map(result=>({lable:"hi", value: "low"}));
    this.setState({
      selectedDrivers: event
    });
    // console.log("selected driver",this.state.selectedDriver);
  }

  handleSubmit = event => {
    event.preventDefault();
    debugger;
    console.log("selected truck", this.state.selectedTruck);

    let newLoad = {
      loadId: this.loadId.current.value,
      shippingMethod: this.state.checkedShippingMethod,
      selectedTruck: this.state.selectedTruck.map(value => {
        return {
          truckId: value.truckId,
          make: value.make,
          model: value.model,
          vin: value.vin
        };
      }),
      selectedDrivers: this.state.selectedDrivers.map(value => {
        return {
          driverId: value.driverId,
          driverName: value.driverName
        };
      }),
      selectedShipper: this.state.selectedShipper.map(value => {
        return {
          shipperId: value.shipperId,
          shipperName: value.shipperName
        };
      }),
      selectedConsignee: this.state.selectedConsignee.map(value => {
        return {
          consigneeId: value.consigneeId,
          consigneeName: value.consigneeName
        };
      }),
      origin: {
        loadLocation: this.state.loadLocation,
        loadTime: this.state.loadTime
      },
      destination: {
        pickupLocation: this.state.pickupLocation,
        pickupTime: this.state.pickupTime
      },
      weight: {
        weightValue: this.state.weightValue,
        weightUnit: this.state.weightUnit
      },
      dimension: {
        l: this.state.l,
        w: this.state.w,
        h: this.state.h,
        dUnit: this.state.dUnit
      },
      quantity: this.state.quantity,
      trip_detail: {
        t_rate: this.state.t_rate,
        t_distance: this.state.t_distance,
        t_cost: this.state.t_cost
      }
    };

    postLoad(newLoad)
      .then(() => {
        //    this.context.setState({loads:[this.context.state.loads, ...newLoad]})

        console.log("UPDATED", this.context.state.loads);

        // this.props.history.push('/');
      })
      .catch(error => {
        console.log("Error", error);
      });
    //  this.props.handleClose();
  };

  onChangeRates() {
    console.log(
      "all ",
      this.state.t_cost,
      this.state.t_rate,
      this.state.t_distance
    );
    this.setState({
      t_cost: this.state.t_rate * this.state.t_distance
    });
  }
  handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({ [name]: value });
    console.log("Handle change", value, name, this.state);

    if (name == "t_rate") {
      this.setState({ t_cost: value * this.state.t_distance });
    } else if (name == "t_distance") {
      this.setState({ t_cost: this.state.t_rate * value });
    } else {
    }
  };
  onValueShippingMethodChange(event) {
    this.setState({
      checkedShippingMethod: event.target.value
    });
  }
  handleTabSelect = (event, newvalue) => {
    this.setState({ tabIndexValue: newvalue });
  };
  handleTabNavigate = newValue => {
    this.setState({ tabIndexValue: newValue });
  };
  onSelectChange = data => {
    this.setState({ loadLocation: data });
  };
  onSelectChangePickup = data => {
    this.setState({ pickupLocation: data });
  };
  render() {
    console.log("this.pickupLocation", this.state.modelvalue[0]);
    return (
      /*   <Paper square className="border border-dark" sm="12"> */
      <Form id="my-form" onSubmit={this.handleSubmit}>
        <Form.Group as={Row}>
          <Col sm="4"></Col>
          <Col>
            <Form.Label column xs="auto" className="font-weight-bold">
              LoadId :
            </Form.Label>

            <Form.Control
              className="text-muted"
              plaintext
              ref={this.loadId}
              value={"L" + (this.state.loadId + 1)}
              readOnly
            />
          </Col>
        </Form.Group>
        <Form.Group>
          <Tabs
            value={this.state.tabIndexValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabSelect}
            aria-label="disabled tabs example"
            variant={this.state.matches ? "fullWidth" : "scrollable"}
            scrollButtons="on"

            /*   className={styles.bigblue} */
          >
            <Tab label="Shipping Method" /* birhanu icon={<PhoneIcon />} */ />

            <Tab label="Truck Information" />
            <Tab label="Driver" />
            <Tab label="Shipper" />
            <Tab label="Consignee" />
            <Tab label="Load Detail" />
            <Tab label="Trip Detail" />
          </Tabs>
        </Form.Group>
        {this.state.tabIndexValue == 0 && (
          <Form.Group
            as={Col}
            className="align-item-center border"
            style={formGroupStyle}
          >
            <Form.Label column="sm"> Ship Method </Form.Label>
            <InputGroup className="justify-content-around align-content-center flex-column ">
              <Form.Check
                type="radio"
                id="1"
                name="shippingMethod"
                label="FLT"
                value="FLT"
                checked={this.state.checkedShippingMethod === "FLT"}
                onChange={this.onValueShippingMethodChange.bind(this)}
              />
              <Form.Check
                type="radio"
                name="shippingMethod"
                id="2"
                label="LTL"
                value="LTL"
                checked={this.state.checkedShippingMethod === "LTL"}
                onChange={this.onValueShippingMethodChange.bind(this)}
              />
            </InputGroup>
          </Form.Group>
        )}
        {this.state.tabIndexValue == 1 && (
          <Form.Group
            as={Col}
            className="align-item-center border"
            style={formGroupStyle}
          >
            <Form.Label column="sm"> Truck </Form.Label>
            <InputGroup>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="truckId"
                defaultSelected={this.state.selectedTruck}
                onChange={
                  selectedTruck => {
                    //  this.state.selectedTruck.push(value);
                    this.setState({
                      selectedTruck
                    });
                    // this.state.selectedTruck.push(value);
                  } /* setSingleSelections */
                }
                options={this.context.state.trucks}
                placeholder="Choose a truck..."
                selected={this.state.selectedTruck}
                renderMenuItemChildren={option => (
                  <div>
                    {option.truckId}
                    <div>
                      <small> MODEL: {option.model} </small> <br />
                      <small> VIN: {option.vin} </small>
                    </div>
                  </div>
                )}
              />
              <InputGroup.Append>
                <CrudOperationModal {...this.state.modelvalue[0]} />
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        )}
        {this.state.tabIndexValue == 2 && (
          <Form.Group
            as={Col}
            className="align-item-center border"
            style={formGroupStyle}
          >
            <Form.Label column="sm"> Driver </Form.Label>
            <InputGroup>
              <Typeahead
                id="public-methods-example"
                labelKey="driverId"
                defaultSelected={this.state.selectedDrivers}
                multiple
                onChange={selectedDrivers => {
                  console.log("Selected", selectedDrivers);

                  this.setState({
                    selectedDrivers
                  });
                }}
                options={this.context.state.drivers}
                placeholder="Choose driver(s)..."
                selected={this.state.selectedDrivers}
                renderMenuItemChildren={option => (
                  <div>
                    {option.driverId}
                    <div>
                      <small> Name: {option.driverName} </small> <br />
                      <small> Email: {option.driverEmail} </small>
                    </div>
                  </div>
                )}
              />
              <InputGroup.Append>
                <CrudOperationModal {...this.state.modelvalue[1]} />
              </InputGroup.Append>
            </InputGroup>
            {/*  <Form.Text className="text-muted">
                          
                          {Object.entries(this.state.selectedDrivers).map(
                            ([key, value], i) => {
                              console.log(
                                "result",
                                key,
                                value,
                                i,
                                Object.values(value)
                              );

                              return <p> {Object.values(value).join(",")} </p>;
                            }
                          )}
                        </Form.Text> */}
            {/*  {this.state.selectedDrivers.length>0?this.state.selectedDrivers[0].join(','):''} */}
          </Form.Group>
        )}
        {this.state.tabIndexValue == 3 && (
          <Form.Group
            as={Col}
            className="align-item-center border"
            style={formGroupStyle}
          >
            <fieldset className="scheduler-border">
              <legend className="scheduler-border"> Load: </legend>
              <Form.Label> Shipper / Sender </Form.Label>
              <InputGroup>
                <Typeahead
                  id="basic-typeahead-single2"
                  labelKey="shipperId"
                  defaultSelected={this.state.selectedShipper}
                  onChange={
                    selectedShipper => {
                      //  this.state.selectedTruck.push(value);
                      this.setState({
                        selectedShipper
                      });
                      // this.state.selectedTruck.push(value);
                    } /* setSingleSelections */
                  }
                  options={this.context.state.shippers}
                  placeholder="Choose a Shipper..."
                  selected={this.state.selectedShipper}
                  renderMenuItemChildren={option => (
                    <div>
                      {option.truckId}
                      <div>
                        <small> {option.shipperId} </small> <br />
                        <small> Name: {option.shipperName} </small>
                      </div>
                    </div>
                  )}
                />
                <InputGroup.Append>
                  <CrudOperationModal {...this.state.modelvalue[2]} />
                </InputGroup.Append>
              </InputGroup>
              <Form.Label> Location </Form.Label>
              <PlaceAutoComplete
                idref="loadPlace"
                add={this.state.loadLocation}
                ref={this.loadLocation}
                onSelectChange={this.onSelectChange}
              />
              <Form.Label> Time </Form.Label>
              <InputGroup>
                {/*  <Form.Control size="sm"    type="datetime-local"  value={this.state.loadTime}  /> */}
                <DatePicker
                  selected={this.state.loadTime}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={date =>
                    this.setState({
                      loadTime: date
                    })
                  }
                />
              </InputGroup>
              <Form.Label> Bill of Loading(BOL) </Form.Label>
              <InputGroup>
                <Form.Control type="file" />
              </InputGroup>
            </fieldset>
          </Form.Group>
        )}
        {this.state.tabIndexValue == 4 && (
          <Form.Group
            as={Col}
            className="align-item-center border"
            style={formGroupStyle}
          >
            <fieldset className="scheduler-border">
              <legend className="scheduler-border"> Pick Up: </legend>
              <Form.Label> Consignee / Receiver </Form.Label>
              <InputGroup>
                <Typeahead
                  id="basic-typeahead-single1"
                  labelKey="consigneeId"
                  defaultSelected={this.state.selectedConsignee}
                  onChange={
                    selectedConsignee => {
                      //  this.state.selectedTruck.push(value);
                      this.setState({
                        selectedConsignee
                      });
                      // this.state.selectedTruck.push(value);
                    } /* setSingleSelections */
                  }
                  options={this.context.state.consignees}
                  placeholder="Choose a consignee..."
                  selected={this.state.selectedConsignee}
                  renderMenuItemChildren={option => (
                    <div>
                      {option.consigneeId}
                      <div>
                        <small> Name: {option.consigneeName} </small> <br />
                      </div>
                    </div>
                  )}
                />
                <InputGroup.Append>
                  <CrudOperationModal {...this.state.modelvalue[3]} />
                </InputGroup.Append>
              </InputGroup>
              <Form.Label> Location </Form.Label>
              <PlaceAutoComplete
                idref="pickupPlace"
                add={this.state.pickupLocation}
                ref={this.pickupLocation}
                onSelectChangePickup={this.onSelectChangePickup}
              />
              <Form.Label> Time </Form.Label>
              <InputGroup>
                {/*  <Form.Control size="sm"    type="datetime-local"  value={this.state.loadTime}  /> */}
                <DatePicker
                  selected={this.state.pickupTime}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={date =>
                    this.setState({
                      pickupTime: date
                    })
                  }
                />
              </InputGroup>
              {/*  <Form.Text className="text-muted">
                                                                                We'll never share your email with anyone else.
                                                                                </Form.Text> */}
            </fieldset>
          </Form.Group>
        )}
        {this.state.tabIndexValue == 5 && (
          <>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border"> Load Detail: </legend>
              <Form.Group
                as={Col}
                className="align-item-center border"
                style={formGroupStyle}
              >
                <Form.Label> Weight </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    type="text"
                    name="weightValue"
                    value={this.state.weightValue}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                  <InputGroup.Append>
                    {/*   <Form.Group> */}
                    <Form.Control
                      size="sm"
                      name="weightUnit"
                      value={this.state.weightUnit}
                      onChange={this.handleChange}
                      as="select"
                    >
                      <option>lb</option> <option>kg</option>
                    </Form.Control>
                    {/* </Form.Group> */}
                  </InputGroup.Append>
                </InputGroup>
                {/* </Form.Group>
                  <Form.Group
                    as={Col}
                    className="align-item-center border"
                    style={formGroupStyle}
                  > */}
                <Form.Label> Dimension </Form.Label>
                <InputGroup className="justify-item-around">
                  <Form.Control
                    size="sm"
                    name="l"
                    value={this.state.l}
                    placeholder="L"
                    onChange={this.handleChange}
                  />
                  <Form.Control
                    size="sm"
                    name="w"
                    value={this.state.w}
                    onChange={this.handleChange}
                    placeholder="W"
                  />
                  <Form.Control
                    size="sm"
                    name="h"
                    value={this.state.h}
                    onChange={this.handleChange}
                    placeholder="H"
                  />
                  <InputGroup.Append>
                    <Form.Control
                      size="sm"
                      name="dUnit"
                      value={this.state.dUnit}
                      onChange={this.handleChange}
                      as="select"
                    >
                      <option>in</option> <option>cm</option>
                    </Form.Control>
                  </InputGroup.Append>
                </InputGroup>

                <Form.Label> Quantity </Form.Label>
                <Form.Control size="sm" ref={this.quantity} placeholder="" />
              </Form.Group>
            </fieldset>
          </>
        )}
        {this.state.tabIndexValue == 6 && (
          <fieldset className="scheduler-border">
            <legend className="scheduler-border"> Trip Detail: </legend>{" "}
            <Form.Group
              as={Col}
              className="align-item-center border col-auto"
              style={formGroupStyle}
            >
              <Form.Label> Rates / mile($) </Form.Label>
              <Form.Control
                size="sm"
                name="t_rate"
                value={this.state.t_rate}
                onChange={this.handleChange}
                placeholder=""
              />

              <Form.Label> Distance(miles) </Form.Label>
              <Form.Control
                size="sm"
                name="t_distance"
                value={this.state.t_distance}
                onChange={this.handleChange}
                placeholder=""
              />

              <Form.Label> Cost($) </Form.Label>
              <Form.Control
                size="sm"
                name="t_cost"
                value={this.state.t_cost}
                placeholder=""
                readOnly
              />
            </Form.Group>
          </fieldset>
        )}

        <Button
          onClick={() => {
            if (this.state.tabIndexValue > 0) {
              this.handleTabNavigate(this.state.tabIndexValue - 1);
            }
          }}
        >
          Previous
        </Button>
        <Button
          onClick={() => {
            if (this.state.tabIndexValue < 6) {
              this.handleTabNavigate(this.state.tabIndexValue + 1);
            }
          }}
        >
          next
        </Button>

        <Button type="submit">Save</Button>
      </Form>

      /* </Paper> */
      /*   <Card>
          <CardContent>
            <Form onSubmit={this.handleSubmit}>
              <Form.Row className="border">
                <Form.Group xs={3} sm={1} as={Col}>
                  <Form.Label column="sm"> Load Id </Form.Label>
                  <Form.Control
                    className="text-muted"
                    size="sm"
                    ref={this.loadId}
                    value={"L" + (this.state.loadId + 1)}
                    readOnly
                  />
                </Form.Group>
                <Form.Group xs={5} sm={2} as={Col} controlId="formBasicEmail">
                  <Form.Label column="sm"> Ship Method </Form.Label>
                  <InputGroup className="justify-content-around">
                    <Form.Check
                      type="radio"
                      id="1"
                      name="shippingMethod"
                      label="FLT"
                      value="FLT"
                      checked={this.state.checkedShippingMethod === "FLT"}
                      onChange={this.onValueShippingMethodChange.bind(this)}
                    />
                    <Form.Check
                      type="radio"
                      name="shippingMethod"
                      id="2"
                      label="LTL"
                      value="LTL"
                      checked={this.state.checkedShippingMethod === "LTL"}
                      onChange={this.onValueShippingMethodChange.bind(this)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group xs={5} sm={4} as={Col}>
                  <Form.Label column="sm"> Truck </Form.Label>
                  <InputGroup>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="truckId"
                      defaultSelected={this.state.selectedTruck}
                      onChange={selectedTruck => {
                        this.setState({
                          selectedTruck
                        });
                      }}
                      options={this.context.state.trucks}
                      placeholder="Choose a truck..."
                      selected={this.state.selectedTruck}
                      renderMenuItemChildren={option => (
                        <div>
                          {option.truckId}
                          <div>
                            <small> MODEL: {option.model} </small> <br />
                            <small> VIN: {option.vin} </small>
                          </div>
                        </div>
                      )}
                    />
                    <InputGroup.Append>
                      <CrudOperationModal {...this.state.modelvalue[0]} />
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group xs={5} sm={5} as={Col}>
                  <Form.Label column="sm"> Driver </Form.Label>
                  <InputGroup>
                    <Typeahead
                      id="public-methods-example"
                      labelKey="driverId"
                      defaultSelected={this.state.selectedDrivers}
                      multiple
                      onChange={selectedDrivers => {
                        console.log("Selected", selectedDrivers);

                        this.setState({
                          selectedDrivers
                        });
                      }}
                      options={this.context.state.drivers}
                      placeholder="Choose driver(s)..."
                      selected={this.state.selectedDrivers}
                      renderMenuItemChildren={option => (
                        <div>
                          {option.driverId}
                          <div>
                            <small> Name: {option.driverName} </small> <br />
                            <small> Email: {option.driverEmail} </small>
                          </div>
                        </div>
                      )}
                    />
                    <InputGroup.Append>
                      <CrudOperationModal {...this.state.modelvalue[1]} />
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group xs={5} sm={6} as={Col}>
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border"> Load: </legend>
                    <Form.Label> Shipper / Sender </Form.Label>
                    <InputGroup>
                      <Typeahead
                        id="basic-typeahead-single2"
                        labelKey="shipperId"
                        defaultSelected={this.state.selectedShipper}
                        onChange={selectedShipper => {
                          this.setState({
                            selectedShipper
                          });
                        }}
                        options={this.context.state.shippers}
                        placeholder="Choose a Shipper..."
                        selected={this.state.selectedShipper}
                        renderMenuItemChildren={option => (
                          <div>
                            {option.truckId}
                            <div>
                              <small> {option.shipperId} </small> <br />
                              <small> Name: {option.shipperName} </small>
                            </div>
                          </div>
                        )}
                      />
                      <InputGroup.Append>
                        <CrudOperationModal {...this.state.modelvalue[2]} />
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Label> Location </Form.Label>
                    <PlaceAutoComplete ref={this.loadLocation} />
                    <Form.Label> Time </Form.Label>
                    <InputGroup>
                      <DatePicker
                        selected={this.state.loadTime}
                        showTimeSelect
                        dateFormat="Pp"
                        onChange={date =>
                          this.setState({
                            loadTime: date
                          })
                        }
                      />
                    </InputGroup>
                    <Form.Label> Bill of Loading(BOL) </Form.Label>
                    <InputGroup>
                      <Form.Control type="file" />
                    </InputGroup>
                  </fieldset>
                </Form.Group>
                <Form.Group xs={5} sm={6} as={Col}>
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border"> Pick Up: </legend>
                    <Form.Label> Consignee / Receiver </Form.Label>
                    <InputGroup>
                      <Typeahead
                        id="basic-typeahead-single1"
                        labelKey="consigneeId"
                        defaultSelected={this.state.selectedConsignee}
                        onChange={selectedConsignee => {
                          this.setState({
                            selectedConsignee
                          });
                        }}
                        options={this.context.state.consignees}
                        placeholder="Choose a consignee..."
                        selected={this.state.selectedConsignee}
                        renderMenuItemChildren={option => (
                          <div>
                            {option.consigneeId}
                            <div>
                              <small> Name: {option.consigneeName} </small>{" "}
                              <br />
                            </div>
                          </div>
                        )}
                      />
                      <InputGroup.Append>
                        <CrudOperationModal {...this.state.modelvalue[3]} />
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Label> Location </Form.Label>
                    <PlaceAutoComplete ref={this.pickupLocation} />
                    <Form.Label> Time </Form.Label>
                    <InputGroup>
                      <DatePicker
                        selected={this.state.pickupTime}
                        showTimeSelect
                        dateFormat="Pp"
                        onChange={date =>
                          this.setState({
                            pickupTime: date
                          })
                        }
                      />
                    </InputGroup>
                  </fieldset>
                </Form.Group>
              </Form.Row>
              <fieldset className="scheduler-border">
                <legend className="scheduler-border"> Load Detail: </legend>
                <Form.Row>
                  <Form.Group xs={5} sm={3} as={Col}>
                    <Form.Label> Weight </Form.Label>
                    <InputGroup>
                      <Form.Control
                        size="sm"
                        ref={this.weightValue}
                        placeholder=""
                      />
                      <InputGroup.Append>
                        <Form.Group>
                          <Form.Control
                            size="sm"
                            ref={this.weightUnit}
                            as="select"
                          >
                            <option> lb. </option> <option> kg </option>
                          </Form.Control>
                        </Form.Group>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group xs={5} sm={3} as={Col}>
                    <Form.Label> Dimension </Form.Label>
                    <InputGroup className="justify-item-around">
                      <Form.Control size="sm" ref={this.l} placeholder="L" />
                      <Form.Control size="sm" ref={this.w} placeholder="W" />
                      <Form.Control size="sm" ref={this.h} placeholder="H" />
                      <InputGroup.Append>
                        <Form.Group>
                          <Form.Control size="sm" ref={this.dUnit} as="select">
                            <option> in </option> <option> cm </option>
                          </Form.Control>
                        </Form.Group>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group xs={5} sm={3} as={Col}>
                    <Form.Label> Quantity </Form.Label>
                    <Form.Control
                      size="sm"
                      ref={this.quantity}
                      placeholder=""
                    />
                  </Form.Group>
                </Form.Row>
              </fieldset>
              <fieldset className="scheduler-border">
                <legend className="scheduler-border"> Trip Detail: </legend>
                <Form.Row>
                  <Form.Group xs={5} sm={2} as={Col}>
                    <Form.Label> Rates / mile($) </Form.Label>
                    <Form.Control
                      size="sm"
                      ref={this.t_rate}
                      onChange={() => this.onChangeRates()}
                      placeholder=""
                    />
                  </Form.Group>
                  <Form.Group xs={5} sm={2} as={Col}>
                    <Form.Label> Distance(miles) </Form.Label>
                    <Form.Control
                      size="sm"
                      ref={this.t_distance}
                      onChange={() => this.onChangeDistance()}
                      placeholder=""
                    />
                  </Form.Group>
                  <Form.Group xs={5} sm={2} as={Col}>
                    <Form.Label> Cost($) </Form.Label>
                    <Form.Control size="sm" ref={this.t_cost} placeholder="" />
                  </Form.Group>
                </Form.Row>
              </fieldset>
              <Form.Row>
                <Form.Group
                  sm={{
                    offset: 11
                  }}
                  as={Col}
                >
                  <Button
                    style={{
                      backgroundColor: "#0077be"
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </CardContent>
        </Card>
 */
    );
  }
}
export default withRouter(CreateLoad);
