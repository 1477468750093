import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";
import firebaseApp from "./../firebase";
export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    };
  }
  handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({
      [name]: value
    });
    console.log("Handle change", value, name, this.state);
  };
  signUp = e => {
    e.preventDefault();
    console.log("signup", this.state);
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(creditional => {
        /* let displayName = this.state.firstName + " " + this.state.lastName;
        console.log(
          "creditional before",
          creditional.user,
          this.state.firstName
        );
        creditional.user.updateProfile({
          displayName: "displayName"
        });
         creditional.user.sendEmailVerification();
        console.log("creditional", creditional.user, this.state.firstName); */

        return firebaseApp.auth().currentUser;
      })
      .then(user => {
        console.log("Signup User ", user);
        debugger;
        user
          .updateProfile({
            displayName: "Jane Q. User",
            photoURL: "https://example.com/jane-q-user/profile.jpg"
          })
          .then(result => {
            console.log("profile updated", result, user);
          })
          .catch(error => {
            console.log("error on updateing profile", error);
          });
        console.log("is userProfile upd", user);
        debugger;
        return 1;
      })
      .then(result => {
        console.log("Signup result", result);
        debugger;
        this.props.onClickNext();
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  render() {
    return (
      <Form onSubmit={this.signUp}>
        <Form.Row>
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> First Name </Form.Label>{" "}
            <Form.Control
              size="sm"
              onChange={this.handleChange}
              name="firstName"
              value={this.state.firstName}
            />{" "}
          </Form.Group>{" "}
          <Form.Group>
            {" "}
            {/*  <Autocomplete
                                                                                        
                                                                                                                 options={this.context.state.trucks}
                                                                                                                 getOptionLabel={(option) =>  option.truckId + "," +option.make + "," + option.model}   
                                                                                                            
                                                                                                            
                                                                                                            onChange={(event, newValue) => {
                                                                                                                console.log("New value",newValue,newValue)
                                                                                                                this.setState({test:newValue?newValue.truckId:""})
                                                                                                            }} 
                                                                                                           
                                                                                                            style={{ width: 300}}
                                                                                                            renderInput={(params) => <TextField {...params} label="Custom filter" variant="outlined" />}
                                                                                                        
                                                                                      /> */}{" "}
          </Form.Group>{" "}
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Last Name </Form.Label>{" "}
            <Form.Control
              size="sm"
              onChange={this.handleChange}
              name="lastName"
              value={this.state.lastName}
            />{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Email </Form.Label>{" "}
            <Form.Control
              size="sm"
              type="email"
              onChange={this.handleChange}
              name="email"
              value={this.state.email}
            />{" "}
          </Form.Group>{" "}
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Phone Number </Form.Label>{" "}
            <Form.Control
              size="sm"
              type="phonenumber"
              onChange={this.handleChange}
              name="phoneNumber"
              value={this.state.phoneNumber}
            />{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Password </Form.Label>{" "}
            <Form.Control
              size="sm"
              onChange={this.handleChange}
              type="password"
              name="password"
              value={this.state.password}
            />{" "}
          </Form.Group>{" "}
          <Form.Group
            sm={{
              span: 6
            }}
            as={Col}
          >
            <Form.Label> Confirm Password </Form.Label>{" "}
            <Form.Control
              size="sm"
              type="password"
              onChange={this.handleChange}
              name="confirmPassword"
              value={this.state.confirmPassword}
            />{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group className="d-flex justify-content-center" as={Col}>
            <Button
              style={{
                backgroundColor: "#0077be"
              }}
              type="submit"
            >
              Sign Up{" "}
            </Button>{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
      </Form>
    );
  }
}
