import React, { Component } from "react";
import { Form, Col, Button, Card, Container } from "react-bootstrap";
import firebaseApp from "./../firebase";
import { AppContext } from "./../service/AppContext";
import { Redirect } from "react-router-dom";
export default class Login extends Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    };
  }

  handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({
      [name]: value
    });
    // console.log("Handle change", value, name, this.state);
  };
  logIn = e => {
    e.preventDefault();
    console.log("log in", this.state);
    const { history } = this.props;
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(creditional => {
        console.log("creditional", creditional, history);

        if (history) history.push("/");
        // this.props.onClickNext();
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  render() {
    if (this.context.state.currentUser) {
      return <Redirect to="/" />;
    }
    return (
      <Container className=" d-flex justify-content-center align-item-center ">
        <Form
          onSubmit={this.logIn}
          className="border"
          style={{
            width: 400,
            padding: 20,
            marginTop: 30
          }}
          /* className="border" */
        >
          <Form.Row>
            <Form.Group
              /* sm={{
        span: 10
      }}
      md={{
        span: 8
      }}
      lg={{
        span: 6
      }}*/
              as={Col}
            >
              <Form.Label> Email </Form.Label>{" "}
              <Form.Control
                type="email"
                onChange={this.handleChange}
                name="email"
                value={this.state.email}
              />{" "}
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row className="justify-content-center">
            <Form.Group
              /*  sm={{
        span: 10
      }}
      md={{
        span: 8
      }}
      lg={{
        span: 6
      }} */
              as={Col}
            >
              <Form.Label> Password </Form.Label>{" "}
              <Form.Control
                onChange={this.handleChange}
                type="password"
                name="password"
                value={this.state.password}
              />{" "}
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group className="d-flex justify-content-center" as={Col}>
              <Button
                style={{
                  backgroundColor: "#0077be"
                }}
                type="submit"
              >
                Sign In{" "}
              </Button>{" "}
            </Form.Group>{" "}
          </Form.Row>{" "}
        </Form>{" "}
      </Container>
    );
  }
}
