import React, { createRef } from "react";
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
//import Row from "react-bootstrap/Row";
import "./../styles/CommonStyle.css";
//import AddIcon from '@material-ui/icons/Add';
//import Button from '@material-ui/core/Button';
import Col from "react-bootstrap/Col";
import { postTruck } from "../service/FirebaseLayer";
//import firebase from 'firebase/app';
import { AppContext } from "./../service/AppContext";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CrudOperationModal } from "./CrudOperationModal";
//import { createFilterOptions } from '@material-ui/lab';

export default class TruckRegistration extends React.Component {
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      fromTruckTable: {
        truckId: 0
      },
      modelvalue: [
        {
          id: 0,
          title: "Truck Registration"
        },
        {
          id: 1,
          title: "Driver Registration"
        },
        {
          id: 2,
          title: "Shipper Registration"
        }
      ],
      selectedDriverId: ""
    };
    this.truckId = createRef({});
    this.vin = createRef({});

    this.make = createRef({});
    this.model = createRef({});
    this.year = createRef({});
    this.color = createRef({});
    this.fuelTankSize = createRef({});
    this.notes = createRef({});
  }
  componentDidMount() {
    console.log(
      "main context",
      this,
      this.context,
      AppContext,
      this.contextType
    );
    if (this.context.state.trucks.length > 0) {
      this.setState({
        fromTruckTable: {
          truckId: parseInt(
            this.context.state.trucks[this.context.state.trucks.length - 1].key
          )
        }
      });
      console.log(
        "The last element",
        this.context.state.trucks[this.context.state.trucks.length - 1]
      );
    }

    // let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
    // console.log("From Truck  local store TRUCK FORM" ,truckFromLStore);
  }
  componentWillUnmount() {}

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    debugger;
    let newtruck = {
      truckId: this.refs.truckId.value,
      vin: this.refs.vin.value,
      year: this.refs.year.value,
      make: this.refs.make.value,
      model: this.refs.model.value,
      color: this.refs.color.value,
      fuelTankSize: this.refs.fuelTankSize.value,

      notes: this.refs.notes.value
    };

    postTruck(newtruck)
      .then(result => {
        console.log("Result", result);

        this.context.setState({
          trucks: [this.context.state.trucks, ...newtruck]
        });

        console.log("UPDATED", this.context.state.trucks);
      })
      .catch(error => {
        console.log("Error", error);
      });
    this.props.handleClose();
  };

  onChangeColor(event) {
    console.log(event.target.value);
  }
  set(selectedTruckData) {
    this.setState({
      fromTruckTable: selectedTruckData
    });
  }
  render() {
    return (
      <Form id="my-form1" onSubmit={this.handleSubmit}>
        <Form.Row>
          <Form.Group xs={5} sm={2} as={Col}>
            <Form.Label> Truck Id </Form.Label>{" "}
            <Form.Control
              className="text-muted"
              ref="truckId"
              size="sm"
              value={"T" + (this.state.fromTruckTable.truckId + 1)}
              readOnly
            />
          </Form.Group>{" "}
          <Form.Group>
            {" "}
            {/*  <Autocomplete
                                                                                    
                                                                                                             options={this.context.state.trucks}
                                                                                                             getOptionLabel={(option) =>  option.truckId + "," +option.make + "," + option.model}   
                                                                                                        
                                                                                                        
                                                                                                        onChange={(event, newValue) => {
                                                                                                            console.log("New value",newValue,newValue)
                                                                                                            this.setState({test:newValue?newValue.truckId:""})
                                                                                                        }} 
                                                                                                       
                                                                                                        style={{ width: 300}}
                                                                                                        renderInput={(params) => <TextField {...params} label="Custom filter" variant="outlined" />}
                                                                                                    
                                                                                  /> */}{" "}
          </Form.Group>{" "}
          <Form.Group xs={5} sm={6} as={Col}>
            <Form.Label column="sm"> VIN# </Form.Label>{" "}
            <Form.Control size="sm" ref="vin" />
          </Form.Group>{" "}
          <Form.Group xs={5} sm={4} as={Col}>
            <Form.Label column="sm"> YEAR </Form.Label>{" "}
            <Form.Control size="sm" ref="year" />
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group xs={5} sm={3} as={Col}>
            <Form.Label> Make </Form.Label>{" "}
            <Form.Control size="sm" ref="make" />
          </Form.Group>{" "}
          <Form.Group xs={5} sm={3} as={Col}>
            <Form.Label> Model </Form.Label>{" "}
            <Form.Control size="sm" ref="model" />
          </Form.Group>{" "}
          <Form.Group xs={5} sm={3} as={Col}>
            <Form.Label> Color </Form.Label>{" "}
            <Form.Control size="sm" ref="color" />
          </Form.Group>{" "}
          <Form.Group xs={5} sm={3} as={Col}>
            <Form.Label> Fuel Tank Size </Form.Label>{" "}
            <Form.Control size="sm" ref="fuelTankSize" />
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group xs={5} sm={12} as={Col}>
            <Form.Label> Notes </Form.Label>{" "}
            <Form.Control as="textarea" rows={2} size="sm" ref="notes" />
          </Form.Group>{" "}
        </Form.Row>{" "}
        <Form.Row>
          <Form.Group
            className="offset-md-10"
            as={Col}
            controlId="formBasicCheckbox"
          >
            <Button
              style={{
                backgroundColor: "#0077be"
              }}
              type="submit"
            >
              Save{" "}
            </Button>{" "}
          </Form.Group>{" "}
        </Form.Row>{" "}
      </Form>
    );
  }
}
