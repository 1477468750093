import * as React from "react";

import Form from "react-bootstrap/Form";
//import AppContext from "./../service/AppContext";
//import InputGroup from 'react-bootstrap/InputGroup';
import Col from "react-bootstrap/Col";
/* global google */
export default class PlaceAutoComplete extends React.Component {
  // static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      address: ""
    };
    //  this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  componentDidMount() {
    this.setState({ address: this.props.add });
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.props.idref),
      {
        types: ["geocode"]
      }
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(["formatted_address"]);
  }
  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    this.setState({
      address: addressObject.formatted_address
    });

    if (this.props.onSelectChange)
      this.props.onSelectChange(this.state.address);
    if (this.props.onSelectChangePickup)
      this.props.onSelectChangePickup(this.state.address);
  }

  render() {
    console.log("Auto complete", this.props.idref);
    return (
      <Form.Control
        size="sm"
        value={this.state.address}
        onChange={address => {
          this.setState({
            address: address.formatted_address
          });
        }}
        /*   ref={this.autocompleteInput} */
        id={this.props.idref}
      />
    );
  }
}
