import React, { useMemo, useContext, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter
} from "react-table";
import { Link } from "react-router-dom";
//import { useSticky } from "react-table-sticky";
//import { Styles } from "./TableStyles";
//import Modal from "react-bootstrap/Modal";
//import Button from "@material-ui/core/Button";
import Button from "react-bootstrap/Button";
import DeleteIcon from "@material-ui/icons/Delete";

import { Truck_COLUMNS } from "./Columns";
import { AppContext } from "../../service/AppContext";
import "./tablestyles.css";
import { PopoverForTables } from "./PopoverForTables";
import { deleteLoad, postTruck } from "./../../service/FirebaseLayer";

import { DeleteModal } from "../DeleteModal";
import { FaEdit, FaSave } from "react-icons/fa";
import { flexbox } from "@material-ui/system";

const updateTruck = updatedTruck => {
  postTruck(updatedTruck)
    .then(() => {
      this.context.setState({
        trucks: [this.context.state.trucks, ...updatedTruck]
      });
      console.log("UPDATED", this.context.state.trucks);
    })
    .catch(error => {
      console.log("Error", error);
    });
  //  console.log("Is the selected row", row.values);
};

export const TruckTable = props => {
  const contextData = useContext(AppContext);
  console.log("contex data", contextData.state);
  const TrucksData = contextData.state.trucks;
  const columns = useMemo(() => Truck_COLUMNS, []);
  const data = useMemo(() => TrucksData, [TrucksData]);
  const [state1, setState1] = useState({});
  const [editState, setEditState] = useState({
    state: false,
    id: 0
  });

  const modelvalue = [
    {
      id: 0,
      title: "Truck Registration",
      action: "edit"
    }
  ];
  console.log("Scatteered", {
    ...modelvalue[0],
    test: "abc"
  });
  //const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  //const [modalIsOpen, setModalIsOpen] = useState(false);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    allColumns

    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "loadId",
            desc: true
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;
  console.log("context Data", contextData);
  return (
    <>
      <div className="modalflex">
        <div className="searchBox">
          <input
            type="text"
            placeholder="Search"
            value={globalFilter || ""}
            onChange={e => setGlobalFilter(e.target.value)}
          />{" "}
        </div>{" "}
        <div className="modalbox">
          <PopoverForTables allColumns={allColumns} />{" "}
        </div>{" "}
      </div>{" "}
      {/*  <Styles>
                          <div
                            {...getTableProps()}
                            className="table sticky"
                            style={{ width: 1000, height: 500 }}
                          >
                            <div className="header">
                              {headerGroups.map(headerGroup => (
                                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                  {headerGroup.headers.map(column => (
                                    <div {...column.getHeaderProps()} className="th">
                                      {column.render("Header")}
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                            <div {...getTableBodyProps()} className="body">
                              {page.map(row => {
                                prepareRow(row);
                                return (
                                  <div {...row.getRowProps()} className="tr">
                                    {row.cells.map(cell => (
                                      <div {...cell.getCellProps()} className="td">
                                        {cell.render("Cell")}
                                      </div>
                                    ))}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Styles> */}{" "}
      <table
        {...getTableProps()}
        style={{
          width: "100%"
        }}
      >
        <thead>
          {" "}
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {" "}
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {" "}
                  {column.render("Header")}{" "}
                  <span>
                    {" "}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? "▼"
                        : "▲"
                      : ""}{" "}
                  </span>{" "}
                </th>
              ))}{" "}
              <th> Action </th>{" "}
            </tr>
          ))}{" "}
        </thead>{" "}
        <tbody {...getTableBodyProps()}>
          {" "}
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {" "}
                {row.cells.map(cell => {
                  console.log("cell", cell.column.id, cell.value, cell.row.id);
                  let cellname = cell.column.id + cell.row.id;
                  // setState1({ cellname: cell.value });
                  return (
                    <td {...cell.getCellProps()}>
                      {" "}
                      {/*   {cell.render("Cell")} */}
                      {editState.state && cell.row.id == editState.id ? (
                        <input
                          type="text"
                          name={cellname}
                          value={state1.cellname}
                          defaultValue={cell.value}
                          onChange={e =>
                            setState1({
                              [e.target.name]: e.target.value
                            })
                          }
                        />
                      ) : (
                        cell.render("Cell")
                      )}{" "}
                    </td>
                  );
                })}{" "}
                <td className="d-flex justify-content-around ">
                  {" "}
                  {editState.id !== row.id ? (
                    <Button
                      size="sm"
                      variant="light"
                      onClick={
                        () => {
                          setEditState({
                            state: true,
                            id: row.id
                          });
                          console.log("Row values", row);
                          // props.setFromTruckTable(row.values);
                          // props.onIntermidate({ fromTruckTable: row.values });
                        }
                        // props.intermidate({ fromTruckTable: row.values })
                      }
                    >
                      {/*   <Link
                                      to={{
                                        pathname: "/updateload",
                                        state: {
                                          fromLoadTable: row.values
                                        }
                                      }}
                                    > */}{" "}
                      <FaEdit /> {/*   </Link> */}{" "}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="light"
                      onClick={
                        () => {
                          /*  updateTruck({
                    truckId:"truckId"+ state1,
                    
                  }); */
                          //  setEditState({ state: true, id: row.id });
                          console.log("save row", state1);
                          // props.setFromTruckTable(row.values);
                          // props.onIntermidate({ fromTruckTable: row.values });
                        }
                        // props.intermidate({ fromTruckTable: row.values })
                      }
                    >
                      {/*   <Link
                                      to={{
                                        pathname: "/updateload",
                                        state: {
                                          fromLoadTable: row.values
                                        }
                                      }}
                                    > */}{" "}
                      <FaSave /> {/*   </Link> */}{" "}
                    </Button>
                  )}{" "}
                  <DeleteModal
                    valueToDelete={{
                      id: row.values.loadId,
                      title: "Load"
                    }}
                  />{" "}
                </td>{" "}
              </tr>
            );
          })}{" "}
        </tbody>{" "}
      </table>{" "}
      <span>
        Go to page: {}{" "}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
          style={{
            width: 50
          }}
        />{" "}
      </span>{" "}
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {" "}
        {"|<<"}{" "}
      </button>{" "}
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        Previous{" "}
      </button>{" "}
      <span>
        Page {} <strong> {pageIndex + 1} </strong> of {pageOptions.length}{" "}
      </span>{" "}
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        Next{" "}
      </button>{" "}
      <button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canPreviousPage}
      >
        {">>|"}{" "}
      </button>{" "}
    </>
  );
};
