import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
//import Button from "@material-ui/core/Button";
//import AddIcon from "@material-ui/icons/Add";
import {
  Navbar,
  Nav,
  Container,
  Button,
  NavDropdown,
  Form,
  FormControl
} from "react-bootstrap";
//import Button from "react-bootstrap/Button";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, NavLink } from "react-router-dom";
import HamburgerMenu from "./UtilityComponents/HamburgerMenu";
import { myColor } from "../styles/CommonStyle";
const HeaderLogin = props => {
  console.log("props", props);
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="mr-auto  align-items-center"
          style={{ maxHeight: "100px" }}
        >
          <Nav.Item style={{ paddingRight: "20px" }}>
            <NavLink
              to="/"
              activeStyle={{
                fontWeight: "bold",
                borderWidth: 5,
                color: myColor.blueColor
              }}
              style={{ color: myColor.grayColor }}
              isActive={(match, location) => {
                if (!match) {
                  return false;
                }

                return match.url === location.pathname;
              }}
            >
              Home
            </NavLink>
          </Nav.Item>

          <NavDropdown title="Link" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              Something else here
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Item style={{ paddingRight: "20px" }}>
            <NavLink
              to="/createload"
              activeStyle={{
                fontWeight: "bold",
                borderWidth: 5,
                color: "#F92B31"
              }}
              style={{ color: "rgba(0, 0, 0, 0.5)" }}
              isActive={(match, location) => {
                // console.log("ML", match.url, "location", location.pathname);
                if (!match) {
                  return false;
                }

                return match.url === location.pathname;
              }}
            >
              New Load
            </NavLink>
          </Nav.Item>
        </Nav>
        <Form className="d-flex" style={{ paddingRight: "20px" }}>
          <FormControl
            type="search"
            placeholder="Search"
            className="mr-2"
            aria-label="Search"
          />
          <Button variant="outline-success">Search</Button>
        </Form>

        <Nav.Item style={{ paddingRight: "20px" }}>
          <Button
            style={{
              backgroundColor: "#2471A3"
              /*  borderColor: "#2471A3" */
            }}
          >
            <NavLink
              to="/SignupforTrial"
              activeStyle={{
                fontWeight: "bold",
                borderWidth: 5,
                color: "#F92B31"
              }}
              style={{ color: "white", fontWeight: "bold", borderWidth: 5 }}
              isActive={(match, location) => {
                // console.log("ML", match.url, "location", location.pathname);
                if (!match) {
                  return false;
                }

                return match.url === location.pathname;
              }}
            >
              Sign Up for Free
            </NavLink>
          </Button>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default HeaderLogin;
