import React from "react";
//import firebase from "firebase/app";
//import "firebase/database";
import firebaseApp from "./../firebase";
export const AppContext = React.createContext();
AppContext.displayName = "mainContext";
export default class Context extends React.Component {
  state = {
    loads: [],
    trucks: [],
    drivers: [],
    shippers: [],
    consignees: [],
    loadLocation: "",

  };
  componentDidMount() {
    console.log("Firebase App", firebaseApp);
    const firebaseRef = firebaseApp.database();
    const firbaseAuth = firebaseApp.auth();
    firbaseAuth.onAuthStateChanged(currentUser => {
      console.log("on auth state change", currentUser)
      this.setState({
        currentUser: currentUser
      });
    });

    firebaseRef.ref("birhanu/Load/").on("value", snapshot => {
      console.log("loads contex", /* snapshot.val(), */ this.state.currentUser);
      // this.setState({loads:snapshot.val()});
      if (snapshot.exists()) {
        // let      Object.entries(snapshot.val()).map(e => Object.assign(e[1], { key: e[0] }));

        this.setState({
          loads: Object.entries(snapshot.val()).map(e =>
            Object.assign(e[1], {
              key: e[0]
            })
          )
        });
        localStorage.setItem("loadstore", JSON.stringify(this.state.loads));
      } else {
        localStorage.removeItem("loadstore");
        this.setState({
          loads: []
        });
      }
      //  let loadsFromLStore=JSON.parse(localStorage.getItem('loadstore') || '[]');
      // console.log("load store",loadsFromLStore.length-1,loadsFromLStore[loadsFromLStore.length-1])
      //   console.log("loads A",this.state.loads[this.state.loads.length-1],this.state.loadId)
      // this.state.loads.push(snapshot.val())
    });

    firebaseRef.ref("birhanu/Truck/").on("value", snapshot => {
      //console.log("truck B",snapshot.val())
      // this.setState({loads:snapshot.val()});
      if (snapshot.exists()) {
        this.setState({
          trucks: Object.entries(snapshot.val()).map(e =>
            Object.assign(e[1], {
              key: e[0]
            })
          )
        });
        localStorage.setItem("truckstore", JSON.stringify(this.state.trucks));
      } else {
        localStorage.removeItem("truckstore");
        this.setState({
          trucks: []
        });
      }
    });
    firebaseRef.ref("birhanu/Shippers/").on("value", snapshot => {
      if (snapshot.exists()) {
        this.setState({
          shippers: Object.entries(snapshot.val()).map(e =>
            Object.assign(e[1], {
              key: e[0]
            })
          )
        });
        localStorage.setItem(
          "shipperstore",
          JSON.stringify(this.state.shippers)
        );
      } else {
        localStorage.removeItem("shipperstore");
        this.setState({
          shippers: []
        });
      }
    });
    firebaseRef.ref("birhanu/Consignees/").on("value", snapshot => {
      if (snapshot.exists()) {
        this.setState({
          consignees: Object.entries(snapshot.val()).map(e =>
            Object.assign(e[1], {
              key: e[0]
            })
          )
        });
        localStorage.setItem(
          "consigneestore",
          JSON.stringify(this.state.consignees)
        );
      } else {
        localStorage.removeItem("consigneestore");
        this.setState({
          consignees: []
        });
      }
    });
    firebaseRef.ref("birhanu/Drivers/").on("value", snapshot => {
      if (snapshot.exists()) {
        this.setState({
          drivers: Object.entries(snapshot.val()).map(e =>
            Object.assign(e[1], {
              key: e[0]
            })
          )
        });
        localStorage.setItem("driverstore", JSON.stringify(this.state.drivers));
      } else {
        localStorage.removeItem("driverstore");
        this.setState({
          drivers: []
        });
      }
    });
    /*  firebaseRef.ref("birhanu/Load/").limitToLast(1).on('child_added',(snap)=> {
         this.setState({loadId:parseInt(snap.key.substring(1))})
        console.log("# count",snap.key)
     }); 
     firebaseRef.ref("birhanu/Truck/").limitToLast(1).on('child_added',(snap)=> {
        this.setState({truckId:parseInt(snap.key.substring(1))})
       console.log("# count",snap.key)
    });  */
  }
  updateGlobalState(data) {
    console.log("update state", data);
    //  this.setState({ ...data });
  }
  render() {
    return ( <
      AppContext.Provider value = {
        {
          state: this.state,
          updateGlobalState: this.updateGlobalState
        }
      } > {
        this.props.children
      } <
      /AppContext.Provider>
    );
  } /*  */
}