import React, { useState, useRef } from "react";
/* import Modal from "react-modal";
Modal.setAppElement("#root"); */
import Overlay from "react-bootstrap/Overlay";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
export const PopoverForTables = props => {
  console.log("modal for tables", props.allColumns);

  return (
    <>
      {/*  <button onClick={handleClick}>...</button> */}
      {/*    <OverlayTrigger> */}
      <OverlayTrigger
        /*   show={show} */
        trigger="click"
        rootClose
        /*  target={target} */
        placement="left-start"
        /*   container={ref.current} */
        containerPadding={20}
        overlay={
          <Popover id="popover-contained">
            {/*   <Popover.Title as="h3">Popover bottom</Popover.Title> */}
            <Popover.Content>
              {/*    <strong>Holy guacamole!</strong> Check this info. */}
              {props.allColumns.map(column => (
                <div key={column.id}>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />
                  {column.Header}
                </div>
              ))}
            </Popover.Content>
          </Popover>
        }
      >
        <button>...</button>
      </OverlayTrigger>
      {/*  </OverlayTrigger> */}
      {/* <button onClick={() => setModalIsOpen(true)}>...</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            position: "fixed",

            zIndex: 0
          },
          content: {
            position: "absolute",
            opacity: 1,
            left: "90%",
            right: 0,
            top: 0,
            height: "50%",
            zIndex: 1
          }
        }}
      >
        {props.allColumns.map(column => (
          <div key={column.id}>
            <input type="checkbox" {...column.getToggleHiddenProps()} />
            {column.Header}
          </div>
        ))}
        <button onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal> */}
    </>
  );
};
