import React, { createRef } from "react";
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
import Form from "react-bootstrap/Form";
//import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
//import Row from "react-bootstrap/Row";
import "./../styles/CommonStyle.css";
//import AddIcon from '@material-ui/icons/Add';
//import Button from '@material-ui/core/Button';
import Col from "react-bootstrap/Col";
import { postTruck } from "../service/FirebaseLayer";
//import firebase from 'firebase/app';
import { AppContext } from "./../service/AppContext";
//import TextField from "@material-ui/core/TextField";
//import Autocomplete from "@material-ui/lab/Autocomplete";
//import { CrudOperationModal } from "./CrudOperationModal";
//import { createFilterOptions } from '@material-ui/lab';

export default class UpdateTruckRegistration extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      fromTruckTable: {
        truckId: 0
      },
      modelvalue: [
        {
          id: 0,
          title: "Truck Registration"
        },
        {
          id: 1,
          title: "Driver Registration"
        },
        {
          id: 2,
          title: "Shipper Registration"
        }
      ],
      selectedDriverId: "",
      selectedTruckData: null
    };
    this.truckId = createRef({});
    this.vin = createRef({});

    this.make = createRef({});
    this.model = createRef({});
    this.year = createRef({});
    this.color = createRef({});
    this.fuelTankSize = createRef({});
    this.notes = createRef({});
  }
  setStateTrucks(value) {
    //consle.log("value passee", value);
  }
  componentDidMount() {
    console.log("Truck regi", this.props.truckData.fromTruckTable);
    this.setState({
      selectedTruckData: this.props.truckData.fromTruckTable
    });
    console.log(
      "main context",

      this.context
    );
    /* if (this.context.state.trucks.length > 0) {
      this.setState({
        fromTruckTable: {
          truckId: parseInt(
            this.context.state.trucks[this.context.state.trucks.length - 1].key
          )
        }
      });
      console.log(
        "The last element",
        this.context.state.trucks[this.context.state.trucks.length - 1]
      );
    }
 */
    // let truckFromLStore= JSON.parse(localStorage.getItem('truckstore') || '[]');
    // console.log("From Truck  local store TRUCK FORM" ,truckFromLStore);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.truckData.fromTruckTable !== this.props.truckData.fromTruckTable
    ) {
      this.setState({
        selectedTruckData: {
          ...this.props.truckData.fromTruckTable
        }
      });
    }

    /*   console.log(
      "update truck render",
      this.state.selectedTruckData,
      this.props.truckData.fromTruckTable
    ); */
  }
  componentWillUnmount() {}

  handleSubmit = event => {
    event.preventDefault();
    let newtruck = {
      truckId: this.refs.truckId.value,
      vin: this.refs.vin.value,
      year: this.refs.year.value,
      make: this.refs.make.value,
      model: this.refs.model.value,
      color: this.refs.color.value,
      fuelTankSize: this.refs.fuelTankSize.value,
      truckDriver: this.state.selectedDriverId,
      notes: this.refs.notes.value
    };
    postTruck(newtruck)
      .then(() => {
        this.context.setState({
          trucks: [this.context.state.trucks, ...newtruck]
        });
        console.log("UPDATED", this.context.state.trucks);
      })
      .catch(error => {
        console.log("Error", error);
      });
    this.props.handleClose();
  };

  onChangeColor(event) {
    console.log(event.target.value);
  }
  onChange = e => {
    this.setState({
      selectedTruckData: {
        vin: e.target.value
      }
    });
  };
  render() {
    // let selectedTruckData = this.props.truckData.fromTruckTable;
    console.log("render", this.state.selectedTruckData);
    if (this.state.selectedTruckData) {
      // this.truckId.value = this.state.selectedTruckData.truckId;
      return (
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group xs={5} sm={2} as={Col}>
              <Form.Label> Truck Id </Form.Label>{" "}
              <Form.Control
                /*  className="text-muted" */
                /*     ref={this.truckId} */
                size="sm"
                ref={element => (this.truckId = element)}
                defaultValue={this.state.selectedTruckData.truckId}
              />{" "}
            </Form.Group>{" "}
            <Form.Group>
              {" "}
              {/*  <Autocomplete
                                                                              
                                                                                                       options={this.context.state.trucks}
                                                                                                       getOptionLabel={(option) =>  option.truckId + "," +option.make + "," + option.model}   
                                                                                                  
                                                                                                  
                                                                                                  onChange={(event, newValue) => {
                                                                                                      console.log("New value",newValue,newValue)
                                                                                                      this.setState({test:newValue?newValue.truckId:""})
                                                                                                  }} 
                                                                                                 
                                                                                                  style={{ width: 300}}
                                                                                                  renderInput={(params) => <TextField {...params} label="Custom filter" variant="outlined" />}
                                                                                              
                                                                            /> */}{" "}
            </Form.Group>{" "}
            <Form.Group xs={5} sm={6} as={Col}>
              <Form.Label column="sm"> VIN# </Form.Label>{" "}
              <Form.Control
                size="sm"
                ref={this.vin}
                onChange={this.onChange}
                value={this.state.selectedTruckData.vin}
              />{" "}
            </Form.Group>{" "}
            <Form.Group xs={5} sm={4} as={Col}>
              <Form.Label column="sm"> YEAR </Form.Label>{" "}
              <Form.Control
                size="sm"
                ref={this.year}
                value={this.state.selectedTruckData.year}
              />{" "}
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group xs={5} sm={3} as={Col}>
              <Form.Label> Make </Form.Label>{" "}
              <Form.Control
                size="sm"
                ref={this.make}
                value={this.state.selectedTruckData.make}
              />{" "}
            </Form.Group>{" "}
            <Form.Group xs={5} sm={3} as={Col}>
              <Form.Label> Model </Form.Label>{" "}
              <Form.Control
                size="sm"
                ref={this.model}
                value={this.state.selectedTruckData.model}
              />{" "}
            </Form.Group>{" "}
            <Form.Group xs={5} sm={3} as={Col}>
              <Form.Label> Color </Form.Label>{" "}
              <Form.Control
                size="sm"
                ref={this.color}
                value={this.state.selectedTruckData.color}
              />{" "}
            </Form.Group>{" "}
            <Form.Group xs={5} sm={3} as={Col}>
              <Form.Label> Fuel Tank Size </Form.Label>{" "}
              <Form.Control
                size="sm"
                ref={this.fuelTankSize}
                value={this.state.selectedTruckData.fuelTankSize}
              />{" "}
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            <Form.Group xs={5} sm={12} as={Col}>
              <Form.Label> Notes </Form.Label>{" "}
              <Form.Control
                as="textarea"
                rows={2}
                size="sm"
                ref={this.notes}
                value={this.state.selectedTruckData.notes}
              />{" "}
            </Form.Group>{" "}
          </Form.Row>{" "}
          <Form.Row>
            <Form.Group
              className="offset-md-10"
              as={Col}
              controlId="formBasicCheckbox"
            >
              <Button
                style={{
                  backgroundColor: "#0077be"
                }}
                type="submit"
              >
                Update{" "}
              </Button>{" "}
            </Form.Group>{" "}
          </Form.Row>{" "}
        </Form>
      );
    } else return null;
  }
}
