const myColor = {
  grayColor: "rgba(0, 0, 0, 0.5)",
  blueColor: "#2471A3",
  redColor: "#F92B31",
  yelloWColor: "#FFC300"
}

const ErrorMessage = {
  color: "red",
  fontSize: "13px"
};
const commonStyles = {
  ErrorMessage: ErrorMessage
};
const formGroupStyle = {
  minHeight: 300,
  color: "#2471A3"
};
const activeNavItemStyle = {
  fontWeight: "bold",
  borderWidth: 5,
  color: "#2471A3"
}
export {
  commonStyles,
  formGroupStyle,
  activeNavItemStyle,
  myColor
};