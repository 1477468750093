import React, {
  useState
} from "react";
import Modal from "react-bootstrap/Modal";
//import Button from "@material-ui/core/Button";
import Button from "react-bootstrap/Button";
import {
  FaTrash
} from "react-icons/fa";
import ModalDialog from "react-bootstrap/ModalDialog";
import {
  deleteLoad
} from "./../service/FirebaseLayer";
let DeleteModal = props => {
  console.log("props", props);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    console.log("delete new way", props.valueToDelete.tile);
    if (props.valueToDelete.title == "Load") {
      deleteLoad(props.valueToDelete.id);
    }
  };
  return ( <
    >
    <
    br / >
    <
    Button size = "sm"
    variant = "light"
    onClick = {
      handleShow
    } >
    <
    FaTrash / >
    <
    /Button>{" "} <
    Modal show = {
      show
    }
    onHide = {
      handleClose
    } >
    <
    Modal.Header closeButton >
    <
    Modal.Title > {
      " "
    } {
      props.valueToDelete.title
    }
    Delete Confirmation {
      " "
    } <
    /Modal.Title>{" "} <
    /Modal.Header>{" "} <
    Modal.Body >
    Are you sure you want to delete({
      props.valueToDelete.id
    }) ? This process can 't be undo.{" "} <
    /Modal.Body>{" "} <
    Modal.Footer >
    <
    Button variant = "secondary"
    onClick = {
      handleClose
    } >
    Close {
      " "
    } <
    /Button>{" "} <
    Button variant = "danger"
    onClick = {
      handleDelete
    } >
    Delete {
      " "
    } <
    /Button>{" "} <
    /Modal.Footer>{" "} <
    /Modal>{" "} <
    />
  );
};
export {
  DeleteModal
};