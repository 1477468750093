import React from "react";
////import { NavigationBar } from "./../routers/NavigationBar";
//import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Link, NavLink } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import Divider from "@material-ui/core/Divider";
import Statistics from "./Statistics";
//const drawerWidth = 240;
export default class ListTruckTasksNav extends React.Component {
  render() {
    return (
      <List>
        <ListItem
          button
          style={{
            color: "#0077be"
          }}
        >
          <NavLink
            to="/"
            activeStyle={{
              fontWeight: "bold",
              borderWidth: 5,
              color: "#2471A3"
            }}
            style={{ color: "rgba(0, 0, 0, 0.5)" }}
            isActive={(match, location) => {
              // console.log("ML", match.url, "location", location.pathname);
              if (!match) {
                return false;
              }

              return match.url === location.pathname;
            }}
          >
            Loads
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            to="/managetruck"
            activeStyle={{
              fontWeight: "bold",
              borderWidth: 5,
              color: "#2471A3"
            }}
            style={{ color: "rgba(0, 0, 0, 0.5)" }}
            isActive={(match, location) => {
              // console.log("ML", match.url, "location", location.pathname);
              if (!match) {
                return false;
              }

              return match.url === location.pathname;
            }}
          >
            Trucks
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            to="/managedrivers"
            activeStyle={{
              fontWeight: "bold",
              borderWidth: 5,
              color: "#2471A3"
            }}
            style={{ color: "rgba(0, 0, 0, 0.5)" }}
            isActive={(match, location) => {
              // console.log("ML", match.url, "location", location.pathname);
              if (!match) {
                return false;
              }

              return match.url === location.pathname;
            }}
          >
            Drivers
          </NavLink>
        </ListItem>
        <Divider />
        <ListItem button> </ListItem>
        {/* <Divider /> */}
        {/* <Divider /> */} <Statistics />
      </List>
    );
  }
}
