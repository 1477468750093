import React, { useMemo, useContext } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  //   useFilters,
  //   useGroupBy,
  //   useExpanded,
  //7   useRowSelect,
  useGlobalFilter
} from "react-table";
import { Link } from "react-router-dom";
//import { useSticky } from "react-table-sticky";
//import { Styles } from "./TableStyles";
//import Modal from "react-bootstrap/Modal";
//import Button from "@material-ui/core/Button";
import Button from "react-bootstrap/Button";
import DeleteIcon from "@material-ui/icons/Delete";

import { LOAD_COLUMNS } from "./Columns";
import { AppContext } from "../../service/AppContext";
import "./tablestyles.css";
import { PopoverForTables } from "./PopoverForTables";
import { deleteLoad } from "./../../service/FirebaseLayer";

import { DeleteModal } from "../DeleteModal";
import { FaEdit } from "react-icons/fa";
import { flexbox } from "@material-ui/system";
const rowSelected = row => {
  console.log("Is the selected row", row.values);
};
export const LoadTable = props => {
  console.log("Tables", props);
  const contextData = useContext(AppContext);
  const LoadsData = contextData.state.loads;
  const columns = useMemo(() => LOAD_COLUMNS, []);
  const data = useMemo(() => LoadsData, [LoadsData]);

  //const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  //const [modalIsOpen, setModalIsOpen] = useState(false);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    allColumns

    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "loadId",
            desc: true
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;
  console.log("context Data", contextData);

  return (
    <>
      <div className="modalflex">
        <div className="searchBox">
          <input
            type="text"
            placeholder="Search"
            value={globalFilter || ""}
            onChange={e => setGlobalFilter(e.target.value)}
          />
        </div>

        <div className="modalbox">
          <PopoverForTables allColumns={allColumns} />
        </div>
      </div>
      {/*  <Styles>
        <div
          {...getTableProps()}
          className="table sticky"
          style={{ width: 1000, height: 500 }}
        >
          <div className="header">
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render("Header")}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {page.map(row => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map(cell => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render("Cell")}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </Styles> */}
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""}
                  </span>{" "}
                </th>
              ))}
              <th>Action</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                  );
                })}
                <td className="d-flex  flex-column  ">
                  <Button size="sm" variant="light">
                    <Link
                      to={{
                        pathname: "/updateload",
                        state: {
                          fromLoadTable: row.values
                        }
                      }}
                    >
                      <FaEdit />
                    </Link>
                  </Button>
                  <DeleteModal
                    valueToDelete={{ id: row.values.loadId, title: "Load" }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <span>
        Go to page: {}{" "}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
          style={{
            width: 50
          }}
        />
      </span>
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {"|<<"}
      </button>
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        Previous
      </button>
      <span>
        Page {} <strong> {pageIndex + 1} </strong> of {pageOptions.length}
      </span>{" "}
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        Next
      </button>
      <button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canPreviousPage}
      >
        {">>|"}
      </button>
    </>
  );
};
