import React, { Component, setState } from "react";
import UpdateTruckRegistration from "./../components/UpdateTruckRegistration";
import { TruckTable } from "./../components/Tables/TruckTable";

export class ManageTrucks extends Component {
  state = {
    fromTruckTable: null,
    isUpdatePress: false
  };

  intermidate = langValue => {
    // this.setState({ isUpdatePress: false });
    this.setState({
      fromTruckTable: langValue,
      isUpdatePress: true
    });
  };
  renderUpdatTruck = () => {
    if (this.state.fromTruckTable !== null) {
      //this.setState({ isUpdatePress: false });
      return <UpdateTruckRegistration truckData={this.state.fromTruckTable} />;
    }
    return null;
  };
  componentDidMount() {
    // this.setState({ fromTruckTable: val });
  }

  render() {
    return (
      <>
        {this.state.fromTruckTable !== null && (
          <UpdateTruckRegistration truckData={this.state.fromTruckTable} />
        )}{" "}
        <TruckTable onIntermidate={this.intermidate} />{" "}
      </>
    );
  }
}
